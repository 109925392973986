import gql from 'graphql-tag'

export const updateNotificationToRead = gql(`
  mutation ($id: ID!) {
    updateUserNotification(input: {id: $id, read: true}) {
      read
      message
      id
      createdAt
      title
      type
      updatedAt
    }
  }`
)
