export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const years = generateArrayOfYears(10);

/**
 * Generate an array of a number of years working back from the
 * current year.
 * @returns {*[]}
 */
function generateArrayOfYears (nNumberOfYears) {
  const max = new Date().getFullYear();
  const min = max - nNumberOfYears;
  const years = [];

  for (let i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}
