import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import logo60 from "assets/img/Roady's-Logo-60.png"
import logo100 from "assets/img/Roady's-Logo-100.png"
import logo200 from "assets/img/Roady's-Logo-200.png"
import logo300 from "assets/img/Roady's-Logo-300.png"
import logo400 from "assets/img/Roady's-Logo-400.png"
import logo500 from "assets/img/Roady's-Logo-500.png"

const Logo = ({ at, width, className, ...rest }) => {
  let img = logo60
  switch (width) {
    case 60:
      img = logo60
      break
    case 100:
      img = logo100
      break
    case 200:
      img = logo200
      break
    case 300:
      img = logo300
      break
    case 400:
      img = logo400
      break
    case 500:
      img = logo500
      break
  }
  return (
    <Link
      to='/'
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-5 mb-4': at === 'auth'
          },
          className
        )}
      >
        <img className='me-2' src={img} alt='Logo' width={width} />
        {/* <span className={classNames('font-sans-serif', textClass)}>falcon</span> */}
      </div>
    </Link>
  )
}

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  width: PropTypes.number,
  className: PropTypes.string,
  textClass: PropTypes.string
}

Logo.defaultProps = { at: 'auth', width: 58 }

export default Logo
