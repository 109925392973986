import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { useRedemptionContext } from 'context/Redemption'
import { useAccountContext } from 'context/Account'
import { formatDateTime, formatDollars } from 'helpers/utils'

const EditRedemption = ({ redemption, show, hide, setShowNotes }) => {
  const { appState } = useAccountContext()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const { editRedemptionRequest } = useRedemptionContext()
  const [user, setUser] = useState(null)
  const [formData, setFormData] = useState({
    gallons: redemption.gallons,
    pump: redemption.pump,
    isCancelled: '',
    editReason: '',
  })
  const [hasNotes, setHasNotes] = useState(false)

  const getSystemNoteCount = e => {
    if (redemption.system_notes === null || typeof redemption.system_notes === 'undefined') {
      return 0;
    } else {
      return Object.keys(redemption.system_notes).length;
    }
  }

  useEffect(() => {
    if (redemption.system_notes === null || typeof redemption.system_notes === 'undefined') {
      setHasNotes(false);
    } else {
      setHasNotes(true);
    }
    setFormData({
      ...formData,
      gallons: redemption.gallons || '0',
      pump: redemption.pump || '0',
      isCancelled: '',
      editReason: ''
    })
  }, [redemption])

  useEffect(() => {
    let isEditable = false;

    if (formData.editReason) {
      if (formData.gallons && formData.gallons.trim !== '' && formData.gallons !== redemption.gallons) {
        isEditable = true;
      }
      if (formData.pump && formData.pump.trim !== '' && formData.pump !== redemption.pump) {
        isEditable = true;
      }
      if (formData.isCancelled && formData.isCancelled === '1') {
        isEditable = true;
      }
    }
    setIsSubmitable(isEditable);
  }, [formData])

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (appState.ready && appState.authenticated) {
      setUser(appState.user)
    }
  }, [appState.user])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    try {
      await editRedemptionRequest(redemption.rid, user, formData.editReason, formData.gallons, formData.pump);
      toast.success('Edit redemption request successfully posted');

      setIsProcessing(false)
      hide()
    } catch (error) {
      setIsProcessing(false)
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={hide}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Edit Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='redeem-group' onSubmit={handleSubmit}>
              <Form.Group as={Col} lg={12} controlId='editRedeem'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reservation ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.rid}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Order ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.order_id}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Fleet Company</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.fleet_co}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.location_name}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Pump</Form.Label>
                    <Form.Control
                      type='text'
                      value={formData.pump}
                      onChange={handleFieldChange}
                      name='pump'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Gallons</Form.Label>
                    <Form.Control
                      type='text'
                      value={formData.gallons}
                      onChange={handleFieldChange}
                      name='gallons'
                    />
                  </Col>
                  <Col>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.trx_status}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Retail Limit</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDollars(redemption.retail_limit)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Gallon Limit</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.gallon_limit}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Exceeded Gallons</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.gallon_exceeded}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Retail Price</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDollars(redemption.retail)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Retail Date</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.retail_date)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reserved On</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.reserved_on)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Redemption Started</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.redeem_started)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Completed On</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.completed_on)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      type='text'
                      value={formData.editReason}
                      onChange={handleFieldChange}
                      name='editReason'
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Text id="editRedeemHelpBlock" muted>
                      Please edit the desired fields and provide a reason.
                    </Form.Text>
                  </Col>
                </Row>
              </Form.Group>
              <Row xs='auto'>
                <Col xxl>
                </Col>
                <Col>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={hide}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={!isSubmitable}
                  >
                    {isProcessing
                      ? (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          className='me-1 fa-spinner fa-spin'
                        />
                        )
                      : ''
                    }
                    Save
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant='primary'
                    type='button'
                    disabled={!hasNotes}
                    onClick={setShowNotes}
                  >
                    Notes <Badge pill bg='secondary'>{getSystemNoteCount()}</Badge>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

EditRedemption.propTypes = {
  redemption: PropTypes.object,
  show: PropTypes.bool,
  hide: PropTypes.func,
  setShowNotes: PropTypes.func,
}

EditRedemption.defaultProps = {
  redemption: {},
}

export default EditRedemption
