import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NotificationDropdown from 'components/navbar/top/NotificationDropdown'
import ProfileDropdown from 'components/navbar/top/ProfileDropdown'
import React, { useEffect, useState } from 'react'
import {
  Nav,
  NavDropdown
} from 'react-bootstrap'
import { useLocationContext } from 'context/Location'
import { useFeatureFlagsContext } from 'context/FeatureFlags'
import { useNotificationContext } from 'context/Notification'

const TopNavRightSideNavItem = () => {
  const { locationState, setActiveViewSet } = useLocationContext()
  const { featureFlagsState } = useFeatureFlagsContext()
  const { notificationState } = useNotificationContext()
  const [currentFeatureFlagsState, setCurrentFeatureFlagsState] = useState(null)

  const [companys, setCompanys] = useState([])
  const [activeData, setActiveData] = useState(null)

  const [currentName, setCurrentName] = useState(null)
  const [currentIcon, setCurrentIcon] = useState(null)
  const [appHasNotifications, setAppHasNotifications] = useState(true)
  const [notificationCount, setNotificationCount] = useState(0)

  useEffect(() => {
    setCurrentFeatureFlagsState(featureFlagsState)
  }, [featureFlagsState])

  useEffect(() => {
    if (notificationState.notifications !== null) {
      setNotificationCount(notificationState.notifications.length)
    }
  }, [notificationState.notifications])

  useEffect(() => {
    if (currentFeatureFlagsState !== null && Object.prototype.hasOwnProperty.call(currentFeatureFlagsState, 'notifications')) {
      setAppHasNotifications(currentFeatureFlagsState.notifications.enabled)
    }
  }, [currentFeatureFlagsState])

  useEffect(() => {
    if (activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setCurrentIcon('globe')
          setCurrentName(activeData.name)
          break
        case 'c':
          setCurrentName(activeData.name)
          setCurrentIcon('city')
          break
        case 'l':
          setCurrentName(activeData.name + ' (' + activeData.id + ')')
          setCurrentIcon('building')
          break
      }
    }
  }, [activeData])

  useEffect(() => {
    if (locationState.company != null) {
      setCompanys(locationState.company)
    }
  }, [locationState.company])

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  const handleLocationChange = l => {
    setActiveViewSet(l)
  }

  return (
    <Nav
      navbar
      className='navbar-nav-icons ms-auto flex-row align-items-center'
      as='ul'
    >
      {
        companys !== null && currentName !== null
          ?
          <NavDropdown
              title={<><FontAwesomeIcon icon={currentIcon} fixedWidth /> {currentName !== null ? currentName : ''}</>}
              id='nav-dropdown'
              className='location-picker'
              onSelect={handleLocationChange}
            >
              { companys.length > 1
                ? <NavDropdown.Item
                key='0'
                eventKey='0'
              >
                <FontAwesomeIcon icon='globe' fixedWidth /> All Companies
              </NavDropdown.Item>
                : ''
              }
              { companys !== null
                ? companys.map((l, i) => {
                  const returnable = []
                  returnable.push(<NavDropdown.Item
                    key={i}
                    eventKey={l.companyid}
                  >
                    <FontAwesomeIcon icon='city' fixedWidth /> {l.companylegalname}
                  </NavDropdown.Item>)

                  let locs = []
                  if (l.locations) {
                    locs = l.locations.map((e, i) => (
                      <NavDropdown.Item
                        key={i}
                        eventKey={e.location_id}
                      >
                        <FontAwesomeIcon icon='building' fixedWidth className='ms-2' /> {e.location_name} ({e.location_id})
                      </NavDropdown.Item>
                    ))
                  }
                  returnable.push(locs)

                  return (
                    returnable
                  )
                })
                : ''
                }
              </NavDropdown>
          : ''
      }

      {
        appHasNotifications
          ?
            <NotificationDropdown />
          : ''
      }
      <ProfileDropdown />
    </Nav>
  )
}

export default TopNavRightSideNavItem
