const user = localStorage.getItem('currentUser')
  ? localStorage.getItem('currentUser')
  : null

const attribs = localStorage.getItem('currentAttribs')
  ? JSON.parse(localStorage.getItem('currentAttribs'))
  : null

const token = localStorage.getItem('currentToken')
  ? localStorage.getItem('currentToken')
  : null

export const initialState = {
  user: '' || user,
  authenticated: user != null,
  token: '' || token,
  loading: false,
  errorMessage: null,
  session: null,
  ready: (token != null || process.env.REACT_APP_ENV === 'demo'),
  attributes: attribs
}

/**
 * Authentication/Account Reducer
 *
 * @param {*} initialState The default authentication state for the application.
 * @param {*} action       The object holding the dispatch type and updated state values
 * @returns The most current Authentication state for the application
 */
export const AuthReducer = (initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'REQUEST_OTP':
      return {
        ...initialState,
        loading: true
      }
    case 'OTP_SUCCESS':
      return {
        ...initialState,
        loading: false
      }
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true
      }
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: payload.user,
        session: payload.session,
        loading: false,
        authenticated: payload.authenticated,
        token: payload.token,
        ready: payload.ready,
        attributes: JSON.parse(payload.attributes)
      }
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
        // token: '',
        authenticated: false,
        ready: false
      }
    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      }
    case 'REFRESH_SESSION':
      return {
        ...initialState,
        token: payload.token,
        session: payload.session,
        authenticated: payload.authenticated,
        ready: payload.ready
      }
    case 'TOKEN_REFRESH':
      return {
        ...initialState,
        token: payload.token,
        session: payload.session,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
