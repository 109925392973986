import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import validator from 'validator'
import { useAccountContext } from 'context/Account'

const validateOTP = number => {
  const isValidOTP = validator.isNumeric(number, { no_symbols: true })
  return isValidOTP
}

const RegistrationConfirmationForm = ({ hasLabel, success, userName }) => {
  const { confirm, resendRegistrationCode } = useAccountContext()
  // State
  const [isRegistering, setRegistering] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [formData, setFormData] = useState({
    code: ''
  })

  useEffect(() => {
    if (validateOTP(formData.code)) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  // Handler
  const handleSubmit = async e => {
    e.preventDefault()
    setRegistering(true)

    try {
      const user = await confirm(userName, formData.code)
      success(user)
    } catch (error) {
      console.error('error signing up:', error)
      toast.error(
        'We\'re having troubles processing your request! Please try again later.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }
  const handleResend = e => {
    e.preventDefault()

    try {
      // eslint-disable-next-line
      const data = resendRegistrationCode(userName)

      toast.success(
        'A new code has been sent to your email address.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    } catch (error) {
      toast.error(
        'We\'re having troubles processing your request! Please try again later.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirmation Code</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirmation Code' : ''}
          value={formData.code}
          name='code'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>

      <Row>
        <Form.Group as={Col}>
          <Button
            type='submit'
            color='primary'
            className='mt-3 w-100 px-1'
            disabled={!isSubmitable}
          >
            {isRegistering
              ? (
                <FontAwesomeIcon
                  icon='circle-notch'
                  className='me-1 fa-spinner fa-spin'
                />
                )
              : (
                  ''
                )}{' '}
            Confirm Account
          </Button>
        </Form.Group>

        <Form.Group as={Col}>
          <Button
            type='button'
            variant='info'
            className='mt-3 w-100'
            onClick={handleResend}
          >
            Resend Code
          </Button>
        </Form.Group>
      </Row>
    </Form>
  )
}

RegistrationConfirmationForm.propTypes = {
  success: PropTypes.func,
  hasLabel: PropTypes.bool,
  userName: PropTypes.string
}

RegistrationConfirmationForm.defaultProps = {
  success: null,
  hasLabel: false,
  userName: ''
}

export default RegistrationConfirmationForm
