import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
// import { setToken, getDataFromAPI } from 'helpers/api'
import { useAccountContext } from 'context/Account'
import { initialState, NotificationReducer } from '../reducers/notificationReducer'
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import awsconfig from 'aws-exports'
import * as queries from 'graphql/queries';
import * as subscriptions from 'graphql/subscriptions';
import * as mutations from 'graphql/mutations';
Amplify.configure(awsconfig);

export const NotificationContext = createContext()
export function NotificationProvider ({ children }) {
  const { appState } = useAccountContext()
  // eslint-disable-next-line
  const [notificationState, dispatch] = useReducer(NotificationReducer, initialState)
  // eslint-disable-next-line
  const [readyState, setReadyState] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated) {
      setReadyState(appState.ready)
      setUser(appState.user)
    }
  }, [appState])

  useEffect(() => {
    if (readyState === true) {
      getListNotifications()
      connectSubs()
    }
  }, [readyState])

  async function connectSubs () {
    API.graphql(
      graphqlOperation(subscriptions.onCreateUserNotification, { user })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
              notification: value.data.onCreateUserNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })

    API.graphql(
      graphqlOperation(subscriptions.onUpdateUserNotification)
    ).subscribe({
      next: ({ provider, value }) => {
        if (value !== undefined && value !== null) {
          dispatch({
            type: 'UPDATE_NOTIFICATION',
            payload: {
              notification: value.data.onUpdateUserNotification,
            },
          })
        }
      },
      error: (error) => console.warn(error)
    })

    API.graphql(
      graphqlOperation(subscriptions.onDeleteUserNotification)
    ).subscribe({
      next: ({ provider, value }) => {
        // leave comment here so function isn't empty (I'm referring to you Jeff) -- Jeff
      },
      error: (error) => console.warn(error)
    })
  }

  async function getListNotifications () {
    const notifications = await API.graphql(graphqlOperation(queries.listAllUserNotifications))
    if (notifications.data.listUserNotifications !== null) {
      dispatch({
        type: 'INIT_NOTIFICATIONS',
        payload: {
          notifications: notifications.data.listUserNotifications.items,
        },
      })
    }
  }

  async function updateNotificationToRead (id) {
    const notifications = await API.graphql(graphqlOperation(mutations.updateNotificationToRead, { id }))
  }

  const sharedState = {
    notificationState,
    updateNotificationToRead
  }

  return (
    <NotificationContext.Provider value={sharedState}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext () {
  const state = useContext(NotificationContext)

  if (state === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider')
  }

  return state
}

NotificationProvider.propTypes = {
  children: PropTypes.any,
}
