/**
 * rewards_location_summary/index.jsx
 * Page component used to display the customer's tracker rewards location summary reports.
 * @author John Nebel <john@roadyscorp.com>
 */
import React, { useState, useEffect } from 'react'
import { useLocationContext } from 'context/Location'
import PageHeader from 'components/common/PageHeader'
import ReportTracker from 'components/reporting/ReportTracker'

/**
 * Creates the display for the Rewards Billing Page, the component that encompasses the page as a whole.
 * @returns {JSX.Element}
 * @constructor
 */
const RewardsLocationSummaryPage = () => {
  // Let's get the location context up in here.
  const { locationState } = useLocationContext()
  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up our local state and handlers.
  const [aLocations, setLocations] = useState([])

  /**
   * When the active view changes, or the company/location data
   * changes, we want to update our local states, so we have things to work
   * with.
   */
  useEffect(() => {
    if (locationState.company !== null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.rewards?.enabled) {
                return l
              }
              return null
            })
          }).flat().map(l => {
            return l.location_id
          }))
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.rewards?.enabled) {
                return l
              }
              return null
            }).map(l => {
              return l.location_id
            }))
          break
        case 'l':
          setLocations([activeData.id])
          break
      }
    }
  }, [activeData, locationState.company])

  // Set up the page header.
  const oPageHeader = (
    <PageHeader
      title='Rewards Location Summary Reports'
      description=""
      className='mb-3'
    />
  )

  // Set up the actual DataTable
  const oReportTracker = <ReportTracker report='rewards_location_summary' locations={aLocations} />

  // Send it all back!
  return (
    <>
      {oPageHeader}
      {oReportTracker}
    </>
  )
}

export default RewardsLocationSummaryPage
