import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify'
import { useLocationContext } from 'context/Location'

const ChangeRetailPrice = ({ hasLabel, retailPrice, show, hide }) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(true)
  const { setActiveLocationRetailPrice } = useLocationContext()
  const [formData, setFormData] = useState({
    retailPrice: '',
  })

  useEffect(() => {
    if (retailPrice !== null) {
      setFormData({
        ...formData,
        retailPrice: retailPrice,
      })
    }
  }, [retailPrice])

  useEffect(() => {
    setIsSubmitable(formData.retailPrice !== '')
  }, [formData])

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    try {
      await setActiveLocationRetailPrice(formData.retailPrice)
      setIsProcessing(false)
      hide()
    } catch (error) {
      setIsProcessing(false)
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <>
      <Modal centered show={show} onHide={hide}>
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Change Retail Price</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row className='mb-3 g-3'>
                <Form.Group as={Col} lg={12} controlId='retailPrice'>
                {hasLabel && <Form.Label>Retail Price</Form.Label>}
                  <Form.Control
                    type='text'
                    placeholder={!hasLabel ? 'Retail Price' : ''}
                    value={formData.retailPrice}
                    name='retailPrice'
                    onChange={handleChange}
                  />
                  <Form.Text id="retailPriceHelpBlock" muted>
                    Please change your retail price or just save it and confirm the current value.
                  </Form.Text>
                </Form.Group>
              </Row>
              <Row className='mb-3 g-3'>
                <Col className='text-end'>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={hide}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col lg='3'>
                  <Button
                    variant='primary'
                    type='submit'
                    disabled={!isSubmitable}
                  >
                    {isProcessing
                      ? (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          className='me-1 fa-spinner fa-spin'
                        />
                        )
                      : ''
                    }
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

ChangeRetailPrice.propTypes = {
  retailPrice: PropTypes.number,
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
}

ChangeRetailPrice.defaultProps = {
  hasLabel: true
}

export default ChangeRetailPrice
