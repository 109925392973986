import React, { useEffect, useState, useContext } from 'react'
import PageHeader from 'components/common/PageHeader'
import DataTable from 'react-data-table-component'
import { useNotificationContext } from 'context/Notification'
import AppContext from 'context/Context'
import { Form, Button, Card } from 'react-bootstrap'
import createMarkup from 'helpers/createMarkup'
import { useParams } from 'react-router-dom'
import { isIterableArray } from 'helpers/utils'
import Skeleton from 'react-loading-skeleton'

const Notification = () => {
  const { noteId } = useParams();
  const {
    config: { isDark }
  } = useContext(AppContext)

  const { notificationState, updateNotificationToRead } = useNotificationContext()
  const [notification, setNotification] = useState(null)
  const [isRead, setIsRead] = useState(false)

  useEffect(() => {
    if (isIterableArray(notificationState.notifications)) {
      const notification = notificationState.notifications.filter(i => {
        if (i.id === noteId) {
          return true
        }
        return false
      })[0]

      setNotification(notification)

      if (!notification.read) {
        setTimeout(function () {
          updateNotificationToRead(notification.id)
        }, 5000)
      }
    }
  }, [notificationState.notifications, noteId])

  return (
    <>
      {notification === null
        ? <Card>
            <Card.Body>
                <Skeleton count={4} />
            </Card.Body>
          </Card>
        : <>
          <PageHeader
        title={notification.title}
        className='mb-3'
        date={notification.createdAt}
        type={notification.type}
      />
      <Card>
        <Card.Body>
          {
            notification.message
              ? notification.message
              : ''
          }
        </Card.Body>
      </Card>
        </>
      }

    </>
  )
}

export default Notification
