import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import CurrentRetail from './LocationCurrentRetail'

import RewardsPerDay from './RewardsPerDay'
import DfsGallonsPerDay from './DfsGallonsPerDay'
import DfsGallonsPerMonth from './DfsGallonsPerMonth'

import { useLocationContext } from 'context/Location'

const LocationBoard = () => {
  const { locationState } = useLocationContext()
  const [location, setLocation] = useState(null)
  const [locationOptions, setLocationOptions] = useState(null)
  const [hasDFS, setHasDFS] = useState(false)
  const [hasRewards, setHasRewards] = useState(false)

  useEffect(() => {
    if (locationState.active_location !== null) {
      setLocation(locationState.active_location)
    }
  }, [locationState.active_location])

  useEffect(() => {
    if (location !== null && location.options !== null) {
      setLocationOptions(location.options);
    }
  }, [location])

  useEffect(() => {
    if (locationOptions !== null) {
      if (locationOptions.dfs?.enabled !== null) {
        setHasDFS(locationOptions.dfs.enabled);
      }
      if (locationOptions.rewards?.enabled !== null) {
        setHasRewards(locationOptions.rewards.enabled);
      }
    }
  }, [locationOptions])

  return (
    <>
      <Row className='g-3 mb-3'>
        <Col md={6} xxl={3}>
          <CurrentRetail />
        </Col>
      </Row>

      {
        // DFS gallons per day widget.
        hasDFS
          ? <Row className={'g3 mb-3'}>
          <Col lg={12}>
            <DfsGallonsPerDay />
          </Col>
          </Row>
          : ''
      }

      {
        // DFS gallons per day widget.
        hasDFS
          ? <Row className={'g3 mb-3'}>
          <Col lg={12}>
            <DfsGallonsPerMonth />
          </Col>
          </Row>
          : ''
      }

      {
        // Rewards points per day widget.
        hasRewards
          ? <Row className={'g3 mb-3'}>
          <Col lg={12}>
            <RewardsPerDay />
          </Col>
          </Row>
          : ''
      }
    </>
  )
}

export default LocationBoard
