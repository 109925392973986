/**
 * dfs_merchant_summary/index.jsx
 * Page component used to display the weekly merchant summary tracked reports.
 * @author John Nebel <john@roadyscorp.com>
 */
import React, { useState, useEffect } from 'react'
import { useLocationContext } from 'context/Location'
import PageHeader from 'components/common/PageHeader'
import ReportTracker from 'components/reporting/ReportTracker'
import { useNavigate } from 'react-router-dom'

/**
 * Creates the display for the DFSRedemptionsPage, the component that encompasses the page as a whole..
 * @returns {JSX.Element}
 * @constructor
 */
const DFSMerchantWeeklySummariesPage = () => {
  // Let's get the location context up in here.
  const { locationState } = useLocationContext()
  const navigate = useNavigate()
  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up our local state and handlers.
  const [aCompanies, setCompanies] = useState([])

  /**
   * When company changes in state, lets update our local company to store a local copy.
   */
  useEffect(() => {
    if (locationState.company !== null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setCompanies(locationState.company.map(c => {
            return c.companyid
          }))
          break
        case 'c':
          setCompanies([locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return true
            }
            return null
          })[0].companyid])
          break
      }
    }
  }, [activeData, locationState.company])

  // Set up the page header.
  const oPageHeader = (
    <PageHeader
      title='DFS Merchant Weekly Summary Reports'
      description=""
      className='mb-3'
    />
  )

  // Set up the actual DataTable
  // Current endpoint accepts a prop called locations, but the lambda itself will use it as the proper
  // newTarget entity to search for.
  const oReportTracker = <ReportTracker report='dfs_merchant_weekly_summary' locations={aCompanies} />

  // Send it all back!
  return (
    <>
      {oPageHeader}
      {oReportTracker}
    </>
  )
}

export default DFSMerchantWeeklySummariesPage
