import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Skeleton from 'react-loading-skeleton'
import { postDataToAPI } from 'helpers/api'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { dateTimeOptions } from 'helpers/formater'
import { isIterableArray } from 'helpers/utils'
import * as demoData from 'data/demo'

/**
 * ReportTrackerProfile component, builds and returns a datatable with ReportTracker data based on the props it receives.
 * @param reportId
 * @param closeHandler
 * @returns {JSX.Element}
 * @constructor
 */
const ReportTrackerProfile = ({ reportId, closeHandler }) => {
  const [aReportData, setReportData] = useState([])
  const [bLoading, setLoading] = useState(false)

  /**
   * Queries for the report tracker data from the db for the profile info.
   * @param aOptions
   * @returns {Promise<[{}]>}
   */
  async function getReportProfileData (aOptions) {
    let aReturn = null

    const sAction = aOptions.action
    const sReportId = aOptions.id

    const oPayload = {
      action: sAction,
      id: sReportId
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const oDetails = await postDataToAPI('/reports', oPayload)
      if (oDetails !== undefined && oDetails.status === 200) {
        aReturn = oDetails.data
      }
    } else {
      aReturn = demoData.getReport(sReportId, sAction)
    }

    return aReturn
  }

  /**
   * downloads CSV action
   */
  function exportCSV () {
    if (aReportData && isIterableArray(aReportData)) {
      const link = document.createElement('a')

      // Generate our csv.
      const oMetadata = aReportData[0].report_metadata
      let sCsv = oMetadata.csvData
      const sFilename = oMetadata.csvFilename
      if (sCsv && sFilename) {
        if (!sCsv.match(/^data:text\/csv/i)) {
          sCsv = `data:text/csv;charset=utf-8,${sCsv}`
        }

        link.setAttribute('href', encodeURI(sCsv))
        link.setAttribute('download', sFilename)
        link.click()
      } else {
        toast.error(
          'There was an error generating your report.  Please contact support for further assistance.',
          {
            autoClose: false,
          }
        )
      }
    } else {
      toast.error(
        'There is no csv data available for this report.',
        {
          autoClose: false,
        }
      )
    }
  }

  /*
   * When the report data has been updated, do these things.
   */
  useEffect(() => {
    // Set loading to false
    setLoading(false)
  }, [aReportData])

  /*
   * When options are updated, trigger getting the report data.
   */
  useEffect(() => {
    // If the reports haven't changed, don't update anything.
    const aOptions = {
      action: 'reportProfile',
      id: reportId
    }

    setLoading(true)

    // Get the report data nad update state once we get something back
    getReportProfileData(aOptions).then(function (data) {
      if (data) {
        setReportData(data)
      } else {
        setReportData([])
      }
    })
  }, [reportId])

  let oComponent = null

  let oExportButton = null
  if (aReportData && isIterableArray(aReportData) && aReportData[0].report_metadata.csvData) {
    oExportButton = (
      <span>
      &nbsp;<Button onClick={exportCSV} size={'sm'}>export raw csv data</Button>
      </span>
    )
  }
  const oReportHeader = (
    <Container>
      <Row>
        <Col>
          <h3>Report Profile</h3>
        </Col>
        <Col>
          <Button onClick={closeHandler} size={'sm'}>&lt; Close Report</Button>
          {oExportButton}
        </Col>
      </Row>
    </Container>
  )

  if (bLoading) {
    oComponent = (
      <Skeleton />
    )
  } else {
    if (isIterableArray(aReportData)) {
      const oReport = aReportData[0]
      const sSentDate = new Intl.DateTimeFormat('en-SU', dateTimeOptions).format(Date.parse((oReport.report_datetime)))
      const oMetadata = oReport.report_metadata
      const oBody = oMetadata.body

      const oStyle = {
        backgroundColor: 'white',
        color: 'black',
        padding: '10px'
      }

      oComponent = (
        <Container>
          <Row>
            <Col>
              The following report was sent on: {sSentDate}
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={oStyle} dangerouslySetInnerHTML={{ __html: oBody }} />
            </Col>
          </Row>
        </Container>
      )
    } else {
      oComponent = (
        <Container>
          <Row>
            <Col>
              <p>No report found: {reportId}</p>
            </Col>
          </Row>
        </Container>
      )
    }
  }

  return (
    <>
      {oReportHeader}
      {oComponent}
    </>
  )
}

/**
 * Set up the prop stuff.
 * @type {{report: Validator<NonNullable<string>>, locations: Validator<NonNullable<any[]>>}}
 */
ReportTrackerProfile.propTypes = {
  reportId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired
}

export default ReportTrackerProfile
