import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import React from 'react'

const Confirm = ({ title, message, isShown, confirmHandler, cancelHandler }) => {
  return (
    <>
      <Modal show={isShown} onHide={cancelHandler}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmHandler}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

Confirm.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isShown: PropTypes.bool,
  confirmHandler: PropTypes.func,
  cancelHandler: PropTypes.func
}

export default Confirm
