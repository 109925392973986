import React from 'react'
import PageHeader from 'components/common/PageHeader'
import { Card } from 'react-bootstrap'
// import { getConfluenceSlugContent } from 'helpers/confluenceContent'

const TutorialPage = () => {
  const content = '' // getConfluenceSlugContent('tutorial')

  return (
    <>
      <PageHeader
        title='Tutorial'
        description=""
        className='mb-3'
      />
      <Card className='mb-3'>
        <Card.Body>
          {content}
        </Card.Body>
      </Card>
    </>
  )
}

export default TutorialPage
