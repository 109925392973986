
import React, { useContext, useEffect, useState } from 'react'
import PageHeader from 'components/common/PageHeader'
import { Col, Row } from 'react-bootstrap'
import { useLocationContext } from 'context/Location'
import { isIterableArray } from 'helpers/utils'
import { currencyOptions, dateTimeOptions } from 'helpers/formater'
import AppContext from 'context/Context'
import DatePicker from 'react-datepicker'

import AdvanceTable from 'components/common/advance-table/AdvanceTable'
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter'
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper'
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox'
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination'

const CompetitorPrices = () => {
  const [date, setDate] = useState(new Date())

  const {
    config: { isDark }
  } = useContext(AppContext)

  // Bring the location context in.
  const { locationState, getCompetitorData } = useLocationContext()
  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  const [locations, setLocations] = useState([])
  const [reportData, setReportData] = useState([])
  const [labelData, setDisplayLabels] = useState(null)
  const [displayData, setDisplayData] = useState(null)
  const [title, setTitle] = useState('DFS Gallons Per Month')

  /**
   * When the active location changes, or the locations, or the locationState
   * changes, we want to update our local states, so we have things to work with.
   */
  useEffect(() => {
    if (locationState.company != null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              return l
            })
          }).flat().map(l => {
            return l.location_id
          }))
          setTitle('Competitor Pricing - All Locations')
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              return l
            }).map(l => {
              return l.location_id
            }))

          setTitle('Competitor Pricing - Company Locations')
          break
        case 'l':
          setLocations([activeData.id])
          if (activeData.name) {
            setTitle('Competitor Pricing - ' + activeData.name + ' (' + activeData.id + ')');
          } else {
            setTitle('Competitor Pricing');
          }
          break
      }
    }
  }, [locationState.company, activeData])

  /**
   * When the locations, month, or year are updated, do these things!
   */
  useEffect(() => {
    if (isIterableArray(locations)) {
      getCompetitorData(locations, date).then(function (rows) {
        if (rows) {
          const targets = [];
          const organizedData = [];
          rows.forEach(function (row, i) {
            if (row.dieselpricedate) {
              row.dieselpricedate = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(row.dieselpricedate))
              row.dieselprice = new Intl.NumberFormat('default', currencyOptions).format(row.dieselprice)
            }
            if (row.defpricedate) {
              row.defpricedate = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(row.defpricedate))
              row.defprice = new Intl.NumberFormat('default', currencyOptions).format(row.defprice)
            }
            if (!targets[row.pmid]) {
              targets[row.pmid] = row.pmid;
              organizedData.push(row);
            }
          })
          setReportData(organizedData);
        } else {
          setReportData([]);
        }
      })
    }
  }, [locations, date])

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'State',
      accessor: 'state',
    },
    {
      Header: 'Def Price',
      accessor: 'defprice',
    },
    {
      Header: 'Diesel Price',
      accessor: 'dieselprice',
    },
    {
      Header: 'Price Date',
      accessor: 'dieselpricedate',
    },
  ]
  // Return things!
  return (
    <>
      <PageHeader
        title={title}
        description=""
        className='mb-3'
      />
      <AdvanceTableWrapper
        columns={columns}
        data={reportData}
        sortable
        pagination
      >
        <Row className="mb-3">
          <Col sm={2}>
            <label>Report Date:</label>
          </Col>
          <Col sm={4}>
            <DatePicker portalId={'datePickerPortal'}
              selected={date}
              onChange={(date) => setDate(date)} />
          </Col>
          <Col xs="auto" sm={6} className='justify-content-end'>
            <AdvanceTableSearchBox table/>
          </Col>
        </Row>
        <AdvanceTable
          table
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3 d-flex" style={{ justifyContent: 'space-between' }}>
          <AdvanceTableFooter
            rowCount={ reportData.length }
            table
            rowInfo
            rowsPerPageSelection
            rowsPerPageOptions={[10, 50, 100]}
          />
          <AdvanceTablePagination table />
        </div>
      </AdvanceTableWrapper>
    </>
  )
}

export default CompetitorPrices
