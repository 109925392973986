import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import {
  Button,
  Form
  // Row,
  // Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import validator from 'validator'
import { useAccountContext } from 'context/Account'

const validateOTP = number => {
  const isValidOTP = validator.isNumeric(number, { no_symbols: true })
  return isValidOTP
}

const ForgotConfirmationForm = ({ hasLabel, success, userName }) => {
  const { forgotRecovery } = useAccountContext()
  // State
  const [isProcessing, setProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [formData, setFormData] = useState({
    code: '',
    password: '',
    confirmPassword: ''
  })

  useEffect(() => {
    if (validateOTP(formData.code) && formData.password !== '' && formData.password === formData.confirmPassword) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  // Handler
  const handleSubmit = e => {
    e.preventDefault()
    setProcessing(true)

    try {
      // eslint-disable-next-line
      const data = forgotRecovery(userName, formData.code, formData.password)
    } catch (error) {
      setProcessing(false)
      toast.error(
        'We\'re having troubles processing your request! Please try again later.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirmation Code</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirmation Code' : ''}
          value={formData.code}
          name='code'
          onChange={handleFieldChange}
          type='text'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          name='password'
          onChange={handleFieldChange}
          type='password'
          value={formData.password}
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Confirm New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm New Password' : ''}
          value={formData.confirmPassword}
          name='confirmPassword'
          onChange={handleFieldChange}
          type='password'
        />
      </Form.Group>

      <Form.Group>
        <Button
          type='submit'
          color='primary'
          className='mt-3 w-100'
          disabled={!isSubmitable}
        >
          {isProcessing
            ? (
              <FontAwesomeIcon
                icon='circle-notch'
                className='me-1 fa-spinner fa-spin'
              />
              )
            : (
                ''
              )}{' '}
          Reset Password
        </Button>
      </Form.Group>
    </Form>
  )
}

ForgotConfirmationForm.propTypes = {
  success: PropTypes.func,
  hasLabel: PropTypes.bool,
  userName: PropTypes.string
}

ForgotConfirmationForm.defaultProps = {
  success: null,
  hasLabel: false,
  userName: ''
}

export default ForgotConfirmationForm
