import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FalconCardHeader from 'components/common/FalconCardHeader'
import { Button, Card, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'context/Account'

const DangerZone = () => {
  const navigate = useNavigate()
  const { deleteAccount } = useAccountContext()

  const [showDangerModal, setShowDangerModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const handleDelete = async () => {
    setShowDangerModal(false)
    setIsProcessing(true)
    try {
      await deleteAccount()
      navigate('/')
    } catch (error) {
      setIsProcessing(false)

      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <>
      <Modal backdrop="static" centered show={showDangerModal}>
        <Modal.Header className="modal-header-danger">
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you certain you want to delete your account? <br />
          This action is limited to your access account alone.
          It will not delete any data related to the locations you can view.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            {isProcessing
              ? (
                <FontAwesomeIcon
                  icon='circle-notch'
                  className='me-1 fa-spinner fa-spin'
                />
                )
              : ''
            }
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setShowDangerModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <FalconCardHeader title="Danger Zone" titleTag="h6" className="d-block d-lg-none py-2" light endEl="" />
        <FalconCardHeader title="Danger Zone" className="d-none d-lg-block" />
        <Card.Body className="bg-light">
          <h5 className="mb-0">Delete this account</h5>
          <p className="fs--1">
            This action will remove all personal data attached to your account.
          </p>
          <Button size='sm' variant="danger" className="w-100" onClick={() => setShowDangerModal(true)}>
            Delete Account
          </Button>
        </Card.Body>
      </Card>
    </>
  )
}

export default DangerZone
