import { dateTimeOptions } from 'helpers/formater'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { isIterableArray } from 'helpers/utils'
import React, { useState, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import PropTypes from 'prop-types'

const NoteGroup = (note) => {
  return (
    <>
      <div className="ms-2 me-auto">
        <div className="fw-bold">{note.date}</div>
        {
          note.notes.merchantconsole_user !== null
          && typeof note.notes.merchantconsole_user !== 'undefined'
            ? (
                <>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Update by: Merchant Staff</div>
                    </div>
                  </ListGroup.Item>
                </>
              )
            : '' }
        {
          note.notes.updated_by !== null
          && typeof note.notes.updated_by !== 'undefined'
          && note.notes.updated_by !== '0'
            ? (
              <>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Update by: DFS Tech Support</div>
                  </div>
                </ListGroup.Item>
              </>
              )
            : '' }
        {
          note.notes.user_notes?.to !== null
          && typeof note.notes.user_notes?.to !== 'undefined'
            ? (
              <>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Note: {decodeHTMLEntities(note.notes.user_notes?.to)}</div>
                  </div>
                </ListGroup.Item>
              </>
              )
            : '' }
        {
          note.notes.gallons?.from !== null
          && note.notes.gallons?.to !== null
          && typeof note.notes.gallons?.from !== 'undefined'
          && typeof note.notes.gallons?.to !== 'undefined'
            ? (
              <>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-start"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Gallons changed from: {note.notes.gallons?.from} to: {note.notes.gallons?.to}</div>
                  </div>
                </ListGroup.Item>
              </>
              )
            : '' }
        {
          note.notes.pump?.from !== null
          && note.notes.pump?.to !== null
          && typeof note.notes.pump?.from !== 'undefined'
          && typeof note.notes.pump?.to !== 'undefined'
            ? (
                <>
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                  >
                    <div className="ms-2 me-auto note-item">
                      <div className="fw-bold">Pump changed from: {note.notes.pump?.from} to: {note.notes.pump?.to}</div>
                    </div>
                  </ListGroup.Item>
                </>
              )
            : '' }
      </div>
    </>
  )
}

function decodeHTMLEntities (text) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

NoteGroup.propTypes = {
  date: PropTypes.string,
  notes: PropTypes.object
}

NoteGroup.defaultProps = {
  date: '',
  notes: {}
}

const Notes = ({ systemNotes, show, hide }) => {
  const [notes, setNotes] = useState({})

  useEffect(() => {
    if (systemNotes !== null && typeof systemNotes !== 'undefined') {
      const notes = Object.keys(systemNotes).reverse().map(function (noteDate) {
        const datetime = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(noteDate))

        return { date: datetime, notes: systemNotes[noteDate] };
      })

      setNotes(notes);
    }
  }, [systemNotes])

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={hide}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup as="ol" className="notes-group">
              {
                isIterableArray(notes)
                  ? notes.map((note, i) => (
                      <NoteGroup key={'note-key-' + i} {...note} />
                  ))
                  :
                  <ListGroup.Item>
                    <div className="ms-2 me-auto">
                      No notes found
                    </div>
                  </ListGroup.Item>
              }
            </ListGroup>
            <Form>
              <Row xs='auto'>
                <Col xxl>
                </Col>
                <Col>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={hide}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

Notes.propTypes = {
  notes: PropTypes.array,
  show: PropTypes.bool,
  hide: PropTypes.func,
  systemNotes: PropTypes.object,
}

Notes.defaultProps = {
  notes: [],
}

export default Notes
