import React, { useContext, useEffect, useState } from 'react'
import PageHeader from 'components/common/PageHeader'
import DataTable, { TableProps } from 'react-data-table-component'
import { useLocationContext } from 'context/Location'
import AppContext from 'context/Context'
import { dateTimeOptions } from 'helpers/formater'
import { Button, Form } from 'react-bootstrap'
import Confirm from 'components/notification/Confirm'
import { useSearchParams } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import { isIterableArray } from 'helpers/utils'
import { useNotificationContext } from 'context/Notification'

const NoRecords = () => (
  <div className='p-4'>There are no records to display</div>
)

const NotAuthorized = () => (
  <div className='p-4'>This location is not authorized to change retail prices</div>
)

const CompanyRetailPrices = () => {
  const {
    config: { isDark }
  } = useContext(AppContext)
  const { locationState, setLocationRetailPrice, setKioskRetailEdit, setActiveViewSet } = useLocationContext()
  const { updateNotificationToRead } = useNotificationContext()
  const [searchParams, setSearchParams] = useSearchParams();

  const [locations, setLocations] = useState([])
  const [isShown, setShown] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [kiosks, setKiosks] = useState([])
  const [activeData, setActiveData] = useState(null)
  const [title, setTitle] = useState('Company Retail Prices')
  const [noDataComponent, setNoDataComponent] = useState(<NoRecords />)
  const [filterValue, setFilterValue] = useState('1')

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  useEffect(() => {
    if (searchParams.has('nid')) {
      updateNotificationToRead(searchParams.get('nid'))
      searchParams.delete('nid')
      setSearchParams(searchParams)
      setActiveViewSet()
    }
  }, [searchParams])

  /**
   * Creates and returns a kiosk component
   * @param nKioskId
   * @returns {JSX.Element}
   */
  function createKioskComponent (nKioskId) {
    return <Button className={'btn-primary'} size={'sm'} style={{ marginRight: '4px' }} key={'key_' + nKioskId} onClick={() => {
      launchKiosk(nKioskId)
    }}>{nKioskId}</Button>
  }

  /**
   * Launches a new kiosk window.
   * @param nKioskId
   */
  function launchKiosk (nKioskId) {
    window.open(process.env.REACT_APP_KIOSK_BASE + '/?deviceid=' + nKioskId, 'kiosk_' + nKioskId, 'menubar=0,resizeable=0,width=534,height=854')
  }

  /**
   * Handles what happens when locationState.company changes.
   */
  useEffect(() => {
    if (locationState.company != null && activeData !== null) {
      let locs = []
      switch (activeData.type) {
        case 'u':
          setTitle('Company Retail Prices')
          setNoDataComponent(<NoRecords />)
          locs = locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.retail_edit.enabled) {
                return l
              }
              return null
            })
          }).flat()
          break

        case 'c':
          setTitle('Company Retail Prices')
          setNoDataComponent(<NoRecords />)
          locs = locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.retail_edit.enabled) {
                return l
              }
              return null
            })
          break

        case 'l':
          setTitle('Retail Price')
          setNoDataComponent(<NotAuthorized />)
          locs = locationState.company.map(c => {
            const loc = c.locations.reduce((acc, cur) => {
              if (cur.location_id === parseInt(activeData.id) && cur.options.retail_edit.enabled) {
                return cur
              }
              return acc
            }, {})
            if (JSON.stringify(loc) !== '{}') {
              return loc
            } else {
              return false
            }
          }).filter(x => {
            return x !== false
          })
          break
      }

      if (filterValue === '1') {
        setLocations(locs.filter(l => {
          if (l.options.dfs?.enabled) {
            return true
          }
          return false
        }))
      } else {
        setLocations(locs)
      }
    }
  }, [locationState.company, activeData, filterValue])

  useEffect(() => {
    if (locationState.kiosks !== null) {
      setKiosks(locationState.kiosks)
    }
  }, [locationState.kiosks])

  /**
   * Define the columns for the datatable.
   * @type {TableProps}
   */
  const columns = [
    {
      name: 'ID',
      selector: row => row.location_id,
      sortable: true,
      width: '4rem',
    },
    {
      name: 'Location',
      selector: row => row.location_name,
      sortable: true,
    },
    {
      name: 'Kiosks',
      selector: row => row.location_id,
      sortable: false,
      cell: (row) => {
        return kiosks.map(k => {
          if (k.location_id === row.location_id) {
            return createKioskComponent(k.kiosk_id)
          }
          return false
        })
      }
    },
    {
      name: 'Kiosk Edit Retail',
      selector: row => row.kiosks,
      sortable: false,
      width: '8rem',
      cell: (row) => {
        return kiosks.reduce((acc, cur, i) => {
          if (cur.location_id === row.location_id) {
            return <Form.Check key={i} type='checkbox'>
              <Form.Check
                type='switch'
                name='kioskretailedit'
                id={ row.location_id }
                key={ row.location_id }
                checked={ cur.kiosk_retail_edit }
                value='1'
                onChange={handleRetailEditChange}
              />
            </Form.Check>
          }
          return acc
        }, [])
      }
    },
    {
      name: 'Retail Price',
      selector: row => row.retail_price,
      sortable: true,
      width: '8rem',
      cell: (row) => {
        return (
          <Form.Control
            type='number'
            value={ row.retail_price }
            placeholder='Retail Price'
            id={ row.location_id }
            key={ row.location_id }
            onChange={handleRetailPriceChange}
          />
        )
      },
    },
    {
      name: 'Retail Date',
      selector: row => {
        if (row.retail_date === null || row.retail_date === '') {
          return ''
        }
        return new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(row.retail_date))
      },
      sortable: true,
    },
  ]

  // today minus one day of milliseconds
  const oldestDate = new Date(Date.now() - 86400000)

  const conditionalRowStyles = [
    {
      when: row => new Date(row.retail_date).getTime() < oldestDate.getTime(),
      classNames: ['price-outdated row-data'],
    },
  ]

  const handleRetailEditChange = e => {
    // kiosks state needs immediate update to eliminate server/network overhead
    setKiosks(kiosks.map(k => {
      if (k.location_id === parseInt(e.target.id)) {
        k.kiosk_retail_edit = e.target.checked
      }
      return k
    }))

    setKioskRetailEdit(e.target.id, e.target.checked)
  }

  const handleRetailPriceChange = e => {
    if (locations !== null) {
      const id = parseInt(e.target.id)
      locations.forEach(location => {
        if (location.location_id === id) {
          location.retail_price = e.target.value
        }
      })
    }
    setLocations([...locations])
  }

  const submitLocationRetailPrice = () => {
    setShown(true)
  }

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRows(selectedRows)
  }

  const cancelSaveRetailPrices = e => {
    setShown(false)
  }

  const saveRetailPrices = e => {
    setShown(false)
    if (selectedRows !== null) {
      selectedRows.forEach(location => {
        setLocationRetailPrice(location.location_id, location.retail_price)
      })
    }
  }

  const filters = [
    { name: 'DFS', value: '1' },
    { name: 'All', value: '2' },
  ]

  return (
    <>
      <PageHeader
        title={title}
        description=""
        className='mb-3'
      />
      {
        isIterableArray(locations)
          ? <Nav>
              <Nav.Item className='pe-2'>
                Location Filter:
              </Nav.Item>
              <Nav.Item className="me-auto">
                <ButtonGroup size="sm">
                  {filters.map((f, i) => (
                    <ToggleButton
                      key={i}
                      id={`filter-${i}`}
                      type="radio"
                      variant='outline-primary'
                      name="filter"
                      value={f.value}
                      checked={filterValue === f.value}
                      onChange={(e) => setFilterValue(e.currentTarget.value)}
                    >
                      {f.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Nav.Item>
            </Nav>
          : ''
      }
      <Form>
        <DataTable
          columns={columns}
          data={locations}
          conditionalRowStyles={conditionalRowStyles}
          theme={isDark ? 'custom-dark' : 'light'}
          selectableRows
          onSelectedRowsChange={handleSelectedRows}
          striped
          noDataComponent={noDataComponent}
        />
        <div className='text-end'>
          {
            isIterableArray(locations)
              ? <Button
                type='button'
                variant='primary'
                className='mt-3 w-10'
                onClick={submitLocationRetailPrice}
                >Submit</Button>
              : ''
          }
        </div>
      </Form>
      <Confirm isShown={isShown} confirmHandler={saveRetailPrices} cancelHandler={cancelSaveRetailPrices} title='Set Retail Price' message='You are about to set all the retail prices for the selected locations.' />
    </>
  )
}

export default CompanyRetailPrices
