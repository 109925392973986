import { useLocationContext } from 'context/Location'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import * as echarts from 'echarts/core'
import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { months, years } from 'data/common'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import Skeleton from 'react-loading-skeleton'
import { isIterableArray } from 'helpers/utils'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
])

const DfsGallonsPerMonth = () => {
  // Bring the location context in.
  const { locationState, getDFSGallonsPerMonthData } = useLocationContext()

  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up some default date things.
  const d = new Date()
  const sDefaultMonth = d.getMonth()
  const sDefaultYear = d.getFullYear()

  // Set up our local state and handlers.
  const [locations, setLocations] = useState([])
  const [month, setMonth] = useState(sDefaultMonth)
  const [year, setYear] = useState(sDefaultYear)
  const [average, setAverage] = useState(0)
  const [runningTotal, setRunningTotal] = useState(0);
  const [reportData, setReportData] = useState([])
  const [bLoading, setLoading] = useState(false)
  const [labelData, setDisplayLabels] = useState(null)
  const [displayData, setDisplayData] = useState(null)
  const [sTitle, setTitle] = useState('DFS Gallons Per Month')

  /**
   * When the active location changes, or the locations, or the locationState
   * changes, we want to update our local states, so we have things to work with.
   */
  useEffect(() => {
    if (locationState.company != null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.dfs?.enabled) {
                return l
              }
              return null
            })
          }).flat().map(l => {
            return l.location_id
          }))
          setTitle('DFS Gallons Per Month - All Locations')
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.dfs?.enabled) {
                return l
              }
              return null
            }).map(l => {
              return l.location_id
            }))

          setTitle('DFS Gallons Per Month - Company Locations')
          break
        case 'l':
          setLocations([activeData.id])
          setTitle('DFS Gallons Per Month')
          break
      }
    }
  }, [locationState.company, activeData])

  /**
   * When the locations, month, or year are updated, do these things!
   */
  useEffect(() => {
    if (isIterableArray(locations)) {
      // Get the data and do something with it.
      setLoading(true)
      getDFSGallonsPerMonthData(locations, year).then(function (rows) {
        const organizedData = []
        if (rows) {
          let average = 0;
          let rowCount = 0;
          let fRunningTotal = 0;
          rows.forEach(function (row, i) {
            organizedData[row.month] = row.gallon_sum || 0.0;
            average = average + parseFloat(row.gallon_sum);
            rowCount++;
          });

          fRunningTotal = average;
          setRunningTotal(new Intl.NumberFormat().format((fRunningTotal).toFixed(3)));
          setAverage(new Intl.NumberFormat().format((average / rowCount).toFixed(3)));
          setReportData(organizedData);
        } else {
          setAverage(0);
          setReportData([]);
        }
      })
    }
  }, [locations, month, year])

  /**
   * When the report data gets updated, we want to update the
   * labels and data things that get used to render the bar graph.
   */
  useEffect(() => {
    // Set the rest of the report data
    setDisplayLabels(Object.keys(reportData));
    setDisplayData(Object.values(reportData));
    setLoading(false);
  }, [reportData])

  const sumDisplay = (
    <div>
      <small>
      Total: {runningTotal}
      </small>
      &nbsp;|&nbsp;
      <small>
      Average: {average}
      </small>
    </div>
  )

  const labelOption = {
    show: true,
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    formatter: '{c}', //  {name|{a}}',
    fontSize: 16,
    color: '#ffffff',
    rich: {
      name: {}
    }
  }

  // The config for our bar chart.
  const config = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: labelData,
        axisLabel: {
          show: true,
          interval: 0,
          rotate: 45,
        },
        axisTick: {
          rotate: 45,
        },
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Gallons Per Month',
        type: 'bar',
        barWidth: '60%',
        data: displayData,
        label: labelOption,
        color: 'rgb(6,165,96)'
      }
    ]
  }

  // Return things!
  return (
    <Card className='h-100'>
      <FalconCardHeader
        title={sTitle}
        titleTag='h4'
        className='pb-0'
        endEl={ <Flex> </Flex> }>
        {sumDisplay}
      </FalconCardHeader>
      <Card.Body>
        {
          bLoading
            ? <Skeleton count={8} />
            : <ReactEChartsCore
          echarts={echarts}
          option={config}
          style={{ height: '18.4375rem' }}
        />
        }
      </Card.Body>
    </Card>
  )
}

export default DfsGallonsPerMonth
