import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Card, Dropdown, ListGroup } from 'react-bootstrap'
import { isIterableArray } from 'helpers/utils'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Notification from 'components/notification/Notification'
import { useNotificationContext } from 'context/Notification'

const NotificationDropdown = () => {
  const { notificationState } = useNotificationContext()

  const [isOpen, setIsOpen] = useState(false)
  const [isAllRead, setIsAllRead] = useState(false)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (notificationState.unreadNotifications !== null) {
      setNotifications(notificationState.unreadNotifications.slice(0, 5))
    }
  }, [notificationState.unreadNotifications])

  useEffect(() => {
    if (notifications.length > 0) {
      setIsAllRead(false)
    } else {
      setIsAllRead(true)
    }
  }, [notifications])

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false)
    })
  }, [])

  return (
    <Dropdown navbar as='li' show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-warning': !isAllRead
        })}
      >
        <FontAwesomeIcon icon='bell' transform='shrink-6' className='fs-4' />
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg'>
        <Card
          className='dropdown-menu-notification dropdown-menu-end shadow-none'
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className='card-header'
            title='Notifications'
            titleTag='h6'
            light={false}
          />
          {
            isIterableArray(notifications)
              ? <ListGroup
                  variant='flush'
                  className='fw-normal fs--1 scrollbar'
                  style={{ maxHeight: '19rem' }}
                >
                  {isIterableArray(notifications) &&
                    notifications.map(notification => (
                      <ListGroup.Item key={notification.id} onClick={handleToggle}>
                        <Notification {...notification} flush />
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              : <Dropdown.Item className='text-center'>
                  <FontAwesomeIcon icon='check-circle' fixedWidth className='me-1 fs-1' style={{ verticalAlign: 'middle', color: 'green' }} />
                  You're All Caught Up
                </Dropdown.Item>
          }

          <div
            className='card-footer text-center border-top'
            onClick={handleToggle}
          >
            <Link className='card-link d-block' to='/notifications'>
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NotificationDropdown
