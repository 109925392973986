import React, { useEffect, useState } from 'react'

import CompanyBoard from './CompanyBoard'
import LocationBoard from './LocationBoard'
import { useLocationContext } from 'context/Location'

const Dashboard = () => {
  const { locationState } = useLocationContext()
  const [activeData, setActiveData] = useState(null)
  const [board, setBoard] = useState(<></>)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  useEffect(() => {
    if (activeData !== null) {
      switch (activeData.type) {
        case 'l':
          setBoard(<LocationBoard />)
          break
        case 'u':
        case 'c':
          setBoard(<CompanyBoard />)
          break
      }
    }
  }, [activeData])

  return (
    board
  )
}

export default Dashboard
