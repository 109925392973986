import { useLocationContext } from 'context/Location'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import * as echarts from 'echarts/core'
import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { months, years } from 'data/common'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import Skeleton from 'react-loading-skeleton'
import { isIterableArray } from 'helpers/utils'
import { add } from 'date-fns'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
])

const RewardsPerDay = () => {
  // Bring the location context in.
  const { locationState, getRewardsPerDayData } = useLocationContext()

  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up some default date things.
  const d = new Date()
  const defaultMonth = d.getMonth()
  const defaultYear = d.getFullYear()

  // Set up our local state and handlers.
  const [location, setLocation] = useState(null)
  const [locations, setLocations] = useState([])
  const [month, setMonth] = useState(defaultMonth)
  const [year, setYear] = useState(defaultYear)

  const [addAverage, setAddAverage] = useState(0)
  const [reportAddData, setReportAddData] = useState([])
  const [dataAdds, setAddData] = useState(null)

  const [redeemAverage, setRedeemAverage] = useState(0)
  const [reportRedeemData, setReportRedeemData] = useState([])
  const [dataRedeems, setRedeemData] = useState(null)

  const [loading, setLoading] = useState(false)
  const [labels, setLabels] = useState(null)
  const [title, setTitle] = useState('Rewards Dollars Per Day')

  /**
   * When the active location changes, or the locations, or the locationState
   * changes, we want to update our local states, so we have things to work with.
   */
  useEffect(() => {
    if (locationState.company != null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.rewards?.enabled) {
                return l
              }
              return null
            })
          }).flat().map(l => {
            return l.location_id
          }))
          setTitle('Rewards Dollars Per Day - All Locations')
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.rewards?.enabled) {
                return l
              }
              return null
            }).map(l => {
              return l.location_id
            }))

          setTitle('Rewards Dollars Per Day - Company Locations')
          break
        case 'l':
          setLocations([activeData.id])
          setTitle('Rewards Dollars Per Day')
          break
      }
    }
  }, [locationState.company, activeData])

  /**
   * When the locations, month, or year are updated, do these things!
   */
  useEffect(() => {
    if (isIterableArray(locations)) {
      // Get the data and do something with it.
      setLoading(true)
      getRewardsPerDayData(locations, month, year).then(function (data) {
        const addData = [];
        const redeemData = [];
        let dayofmonth;
        if (data) {
          data.forEach(function (record) {
            if (record.event_date) {
              dayofmonth = new Date(record.event_date + 'T08:00:00.000+00:00').getDate();

              addData[dayofmonth] = '';
              redeemData[dayofmonth] = '';
              if (record.add_dollars !== null && record.add_dollars > 0) {
                addData[dayofmonth] = parseFloat(record.add_dollars);
              }
              if (record.redeem_dollars !== null && record.redeem_dollars > 0) {
                redeemData[dayofmonth] = parseFloat(record.redeem_dollars);
              }
            }
          })
        }
        setReportAddData(addData);
        setReportRedeemData(redeemData);
      })
    }
  }, [locations, month, year])

  /**
   * When the report data gets updated, we want to update the
   * labels and data things that get used to render the bar graph.
   */
  useEffect(() => {
    const today = new Date();
    const dates = getAllDaysInMonth(month, year);
    const labelData = [];
    const addData = [];
    const redeemData = [];

    let days = 0;
    let addCurrency = 0;
    let redeemCurrency = 0;
    let runningAddAverage = 0;
    let runningRedeemAverage = 0;

    dates.forEach(function (date) {
      // We only want to average out the days that come before TODAY, so if we're mid-month, we'll only include the days
      // that have passed, not the 0 days that are yet to come.
      addCurrency = parseFloat(reportAddData[date] ?? 0);
      redeemCurrency = parseFloat(reportRedeemData[date] ?? 0);

      if (new Date(date) <= today) {
        runningAddAverage += addCurrency;
        runningRedeemAverage += redeemCurrency;
        days++;
      }

      labelData[date] = addCurrency - redeemCurrency;
      addData[date] = addCurrency > 0 ? addCurrency.toFixed(2) : '';
      redeemData[date] = redeemCurrency > 0 ? redeemCurrency.toFixed(2) : '';
    })

    // Get the actual average and set it.
    runningAddAverage = parseFloat(runningAddAverage / days).toFixed(2);
    runningRedeemAverage = parseFloat(runningRedeemAverage / days).toFixed(2);
    setAddAverage(runningAddAverage);
    setRedeemAverage(runningRedeemAverage);

    // Set the rest of the report data
    const dataAdds = Object.values(addData);
    const dataRedeems = Object.values(redeemData);

    setLabels(Object.keys(labelData));
    setAddData(dataAdds);
    setRedeemData(dataRedeems);
    setLoading(false)
  }, [reportAddData, reportRedeemData])

  /**
   * Returns an array with all the dates in a month, in YYYY-MM-DD format.
   * @param month
   * @param year
   * @returns {*[]}
   */
  function getAllDaysInMonth (month = 0, year = 0) {
    const date = new Date(year, month, 1)
    const dates = []

    while (date.getMonth() === month) {
      dates.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }

    return dates
  }

  // The month selector component.
  const monthSelector = (
    <Form.Select
      size='sm'
      value={month}
      onChange={e => setMonth(parseInt(e.target.value))}
      className='me-2'
      style={{ width: '200px' }}
    >
      {months.map((month, index) => (
        <option value={index} key={'rewards-' + month}>
          {month}
        </option>
      ))}
    </Form.Select>
  )

  // The year selector component
  const yearSelector = (
    <Form.Select
      size='sm'
      value={year}
      onChange={e => setYear(e.target.value)}
      className='me-2'
    >
      {years.map((year, index) => (
        <option value={year} key={'rewards-' + year}>
          {year}
        </option>
      ))}
    </Form.Select>
  )

  const addAverageDisplay = (
    <div>
      <small>
      Add Average: {addAverage}
      </small>
    </div>
  )

  const redeemAverageDisplay = (
    <div>
      <small>
      Redeem Average: {redeemAverage}
      </small>
    </div>
  )

  const labelOption = {
    show: true,
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    formatter: '{c}',
    fontSize: 12,
    color: '#ffffff',
  }

  // The config for our bar chart.
  const config = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    events: [],
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: labels,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Adds Per Day',
        type: 'bar',
        barWidth: '45%',
        barGap: '0',
        align: 'center',
        data: dataAdds,
        label: labelOption,
        color: 'rgb(6,165,96)'
      },
      {
        name: 'Redeems Per Day',
        type: 'bar',
        barWidth: '45%',
        barGap: '0',
        align: 'center',
        data: dataRedeems,
        label: labelOption,
        color: 'rgb(201,73,0)'
      }
    ]
  }

  // Return things!
  return (
    <Card className='h-100'>
      <FalconCardHeader
        title={title}
        titleTag='h4'
        className='pb-0'
        endEl={
          <Flex>
            {monthSelector}
            {yearSelector}
          </Flex>
        }>
        {addAverageDisplay}
        {redeemAverageDisplay}
      </FalconCardHeader>
      <Card.Body>
        {
          loading
            ? <Skeleton count={8} />
            : <ReactEChartsCore
          echarts={echarts}
          option={config}
          style={{ height: '18.4375rem' }}
        />
        }
      </Card.Body>
    </Card>
  )
}

export default RewardsPerDay
