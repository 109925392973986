import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'context/Account'
import { toast } from 'react-toastify'

const ChangePassword = ({ hasLabel, show, hide }) => {
  const { changePassword } = useAccountContext()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [formData, setFormData] = useState({
    old: '',
    new: '',
    confirm: '',
  })

  useEffect(() => {
    if (formData.old && formData.new && formData.confirm) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  const handleChange = e => {
    const fieldValue = e.target.value

    setFormData({
      ...formData,
      [e.target.name]: fieldValue,
    })
  }

  const handlePassSubmit = async (e) => {
    e.preventDefault()
    if (formData.new !== formData.confirm) {
      toast.error(
        'Password confirmation does not match',
        {
          autoClose: false,
        }
      )
      return
    }

    setIsProcessing(true)
    try {
      await changePassword(formData.old, formData.new)
      setIsProcessing(false)

      toast.success('Password has been successfully changed')
      hide()
    } catch (error) {
      setIsProcessing(false)

      toast.error(
        error.message === 'Incorrect username or password.' ? '' : error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  const resetForm = () => {
    setFormData({
      old: '',
      new: '',
      confirm: '',
    })
  }

  return (
    <>
      <Modal centered show={show} onHide={hide} onShow={resetForm}>
        <Modal.Header className='modal-header-success'>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handlePassSubmit}>
            <Row className='mb-3 g-3'>
              <Form.Group as={Col} lg={12} controlId='old'>
              {hasLabel && <Form.Label>Old Password</Form.Label>}
                <Form.Control
                  type='password'
                  placeholder={!hasLabel ? 'Old Password' : ''}
                  value={formData.old}
                  name='old'
                  onChange={handleChange}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Your password must be at least 8 characters long, contain mixed-case letters, numbers, special characters
                  and must not contain spaces, or emoji.
                </Form.Text>
              </Form.Group>
            </Row>
            <Row className='mb-3 g-3'>
              <Form.Group as={Col} lg={12} controlId='new'>
              {hasLabel && <Form.Label>New Password</Form.Label>}
                <Form.Control
                  type='password'
                  placeholder={!hasLabel ? 'New Password' : ''}
                  value={formData.new}
                  name='new'
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className='mb-3 g-3'>
              <Form.Group as={Col} lg={12} controlId='confirm'>
              {hasLabel && <Form.Label>Confirm New Password</Form.Label>}
                <Form.Control
                  type='password'
                  placeholder={!hasLabel ? 'Confirm New Password' : ''}
                  value={formData.confirm}
                  name='confirm'
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row className='g-3'>
              <Col>
                <Button
                  variant='secondary'
                  type='button'
                  onClick={hide}
                >
                  Cancel
                </Button>
              </Col>
              <Col className='text-end'>
                <Button
                  variant='primary'
                  type='submit'
                  disabled={!isSubmitable}
                >
                  {isProcessing
                    ? (
                      <FontAwesomeIcon
                        icon='circle-notch'
                        className='me-1 fa-spinner fa-spin'
                      />
                      )
                    : ''
                  }
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

ChangePassword.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool
}

ChangePassword.defaultProps = {
  hasLabel: true
}

export default ChangePassword
