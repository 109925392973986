import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAccountContext } from 'context/Account'
import { postDataToAPI } from 'helpers/api'
import { Amplify } from 'aws-amplify';
import awsconfig from 'aws-exports'

Amplify.configure(awsconfig);

export const RedemptionContext = createContext()
export function RedemptionProvider ({ children }) {
  const { appState } = useAccountContext()
  const [readyState, setReadyState] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (appState.ready && appState.authenticated) {
      setReadyState(appState.ready)
      setUser(appState.user)
    }
  }, [appState])

  async function editRedemptionRequest (resId, userId, reason, gallons, pump) {
    const payload = {
      reservationId: resId,
      userId: userId,
      gallons: gallons,
      pump: pump,
      reason: reason
    }

    await postDataToAPI('/dfs/editRedeem', payload)
  }

  const sharedState = {
    editRedemptionRequest
  }

  return (
    <RedemptionContext.Provider value={sharedState}>
      {children}
    </RedemptionContext.Provider>
  )
}

export function useRedemptionContext () {
  const state = useContext(RedemptionContext)

  if (state === undefined) {
    throw new Error('useRedemptionContext must be used within a RedemptionProvider')
  }

  return state
}

RedemptionProvider.propTypes = {
  children: PropTypes.any,
}
