import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import createMarkup from 'helpers/createMarkup'
// import Avatar from 'components/common/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const dateTimeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true,
  timeZoneName: 'short'
}

const Notification = ({
  type,
  title,
  createdAt,
  className,
  read,
  flush,
  id
}) => {
  const eventDate = new Date(createdAt)
  const eventDateFormatted = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(eventDate)

  const note = (type) => {
    switch (type) {
      case 'retail':
        return {
          icon: 'dollar-sign',
          color: 'green',
          to: `/pricing?nid=${id}`
        }

      default:
        return {
          icon: 'message',
          color: 'grey',
          to: `/notifications/${id}`
        }
    }
  }

  return (
      <Link
        className={classNames(
          'notification',
          { 'notification-unread': read, 'notification-flush': flush },
          // className
        )}
        to={note(type).to}
        // onClick={nav(id)}
      >
        {type && (
          <div className='notification-avatar pe-2'>
            <span className='fa-layers fa-fw fs-3'>
              <FontAwesomeIcon icon="circle" color={note(type).color} />
              <FontAwesomeIcon icon={note(type).icon} inverse transform="shrink-8" />
            </span>
          </div>
        )}
        <div className='notification-body'>
          <p className='mb-1'>{title}</p>

          <span className='notification-time'>
            {eventDateFormatted}
          </span>
        </div>
      </Link>
  )
}

Notification.propTypes = {
  type: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  className: PropTypes.string,
  read: PropTypes.bool,
  flush: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
}

Notification.defaultProps = { read: false, flush: false }

export default Notification
