import gql from 'graphql-tag'

export const onCreateUserNotification = gql(`
  subscription ($user: String!) {
    onCreateUserNotification(user: $user) {
      id
      createdAt
      type
      id
      message
      read
      title
      updatedAt
    }
  }`
)

export const onUpdateUserNotification = gql(`
  subscription {
    onUpdateUserNotification {
      createdAt
      type
      id
      message
      read
      title
      updatedAt
    }
  }`
)

export const onDeleteUserNotification = gql(`
  subscription ($user: String!) {
    onDeleteUserNotification(user: $user) {
      id
    }
  }`
)
