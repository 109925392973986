// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import Avatar from 'components/common/Avatar'
import { toast } from 'react-toastify'
import { useAccountContext } from 'context/Account'

const ProfileDropdown = () => {
  const navigate = useNavigate()
  const { logout, appState } = useAccountContext()
  const [profilePic, setProfilePic] = useState(null)

  const [isConfigured, setIsConfigured] = useState(false)

  useEffect(() => {
    if (appState.ready && appState.attributes !== null) {
      if (Object.prototype.hasOwnProperty.call(appState.attributes, 'custom:companyid')) {
        setIsConfigured(appState.ready)
      }

      if (Object.prototype.hasOwnProperty.call(appState.attributes, 'custom:picture')) {
        setProfilePic(appState.attributes['custom:picture'])
      }
    }
  }, [appState])

  const handleSignout = async () => {
    try {
      await logout()
      navigate('/')
    } catch (error) {
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <Dropdown navbar as='li'>
      <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className='pe-0 ps-2 py-0 nav-link'
      >
        <Avatar src={profilePic} />
      </Dropdown.Toggle>
      {process.env.REACT_APP_ENV !== 'demo'
        ? <Dropdown.Menu className='dropdown-caret dropdown-menu-card  dropdown-menu-end'>
            <div className='bg-white rounded-2 py-2 dark__bg-1000'>
              {/* <Dropdown.Item className="fw-bold text-warning" href="#!">
                <FontAwesomeIcon icon="crown" className="me-1" />
                <span>Go Pro</span>
              </Dropdown.Item> */}
              {/* <Dropdown.Divider /> */}
              {/* <Dropdown.Item href="#!">Set status</Dropdown.Item> */}
              {isConfigured
                ? <Dropdown.Item as={Link} to='/user/profile'>
                    Profile &amp; Account
                  </Dropdown.Item>
                : ''
              }
              {/* {isConfigured
                ? <Dropdown.Item href='#!'>Feedback</Dropdown.Item>
                : ''
              } */}
              {isConfigured
                ? <Dropdown.Divider />
                : ''
              }

              {/* <Dropdown.Item as={Link} to="/user/settings">
                Settings
              </Dropdown.Item> */}
              <Dropdown.Item as='button' onClick={handleSignout}>
                Logout
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        : ''
      }
    </Dropdown>
  )
}

export default ProfileDropdown
