import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DataTable from 'react-data-table-component'
import Skeleton from 'react-loading-skeleton'
import AppContext from 'context/Context'
import { postDataToAPI } from 'helpers/api'
import { Button } from 'react-bootstrap'
import ReportTrackerProfile from 'components/reporting/ReportTrackerProfile'
import { dateTimeOptions } from 'helpers/formater'
import { isIterableArray } from 'helpers/utils'
import { useLocationContext } from 'context/Location'
import * as demoData from 'data/demo'

/**
 * ReportTracker component, builds and returns a datatable with ReportTracker data based on the props it receives.
 * @param report
 * @param locations
 * @returns {JSX.Element}
 * @constructor
 */
const ReportTracker = ({ report, locations }) => {
  const { locationState } = useLocationContext()

  const [activeData, setActiveData] = useState(null)

  const [aReportData, setReportData] = useState([])
  const [bLoading, setLoading] = useState(true)
  const [aReducedReportData, setReducedReportData] = useState([])
  const [reportId, setReportId] = useState(null)
  const [bShowReport, setShowReport] = useState(false)
  const { config: { isDark } } = useContext(AppContext)
  let sTitle = ''

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  useEffect(() => {
    if (activeData !== null) {
      // Active View set to Single locations can not see
      closeProfile().then(r => null);
    }
  }, [activeData])

  const aColumns = [
    {
      name: 'Report Id',
      selector: row => row.report_tracker_id,
      key: 'report_tracker_id',
      sortable: true,
      width: '7rem',
    },
    // {
    //   name: 'LID',
    //   selector: row => row.lid,
    //   key: 'lid',
    //   sortable: true,
    // },
    {
      name: 'Location',
      selector: row => row.locationname,
      key: 'locationname',
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.date,
      key: 'date',
      sortable: true,
      cell: (row) => {
        return (
          new Intl.DateTimeFormat('en-SU', dateTimeOptions).format(Date.parse(row.date))
        )
      },
    },
    {
      name: 'Link',
      selector: row => row.link,
      key: 'link',
      sortable: false
    }
  ]

  /**
   * Queries for the report tracker data from the db.
   * @param aOptions
   * @returns {Promise<[{}]>}
   */
  async function getReportTrackerData (aOptions) {
    let aReturn = null

    const sAction = aOptions.action
    const aLocations = aOptions.locations
    const sReport = aOptions.report

    const oPayload = {
      action: sAction,
      locations: aLocations,
      report: sReport
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const oDetails = await postDataToAPI('/reports', oPayload)
      if (oDetails !== undefined && oDetails.status === 200) {
        aReturn = oDetails.data
      }
    } else {
      aReturn = demoData.getReports(sReport, 5, aLocations)
    }

    return aReturn
  }

  /*
   * When the report data has been updated, do these things.
   */
  useEffect(() => {
    if (Array.isArray(aReportData)) {
      const aReducedReportData = reduceReportData(aReportData, aColumns)
      setReducedReportData(aReducedReportData)
      setLoading(false)
    }
  }, [aReportData])

  /*
   * When options are updated, trigger getting the report data.
   */
  useEffect(() => {
    // Modify title based on number of locations
    const nCount = locations.length
    if (nCount > 0 && report !== '') {
      if (nCount > 1) {
        sTitle = 'All Your Locations'
      } else {
        sTitle = 'This Location'
      }

      // If the reports haven't changed, don't update anything.
      const aOptions = {
        action: 'reportList',
        locations: locations,
        report: report
      }

      setLoading(true)

      // Get the report data nad update state once we get something back
      getReportTrackerData(aOptions).then(function (data) {
        if (data) {
          setReportData(data)
        } else {
          setReportData([])
        }
      })
    }
  }, [locations, report])

  /**
   * Triggered when a report profile button is clicked.
   * @param trigger
   */
  async function showProfile (trigger) {
    const nReportProfileId = trigger.target.attributes.getNamedItem('data-report-tracker-id').value
    setReportId(nReportProfileId)
    setShowReport(true)
  }

  /**
   * Callback handler for closing the profile.
   * @returns {Promise<void>}
   */
  async function closeProfile () {
    setReportId(null)
    setShowReport(false)
  }

  /**
   * Takes a report, and reduces it to the columns that we care about.
   * @param aData
   * @param aColumns
   */
  function reduceReportData (aData, aColumns) {
    const aReturn = []
    aData.forEach(function (oRecord) {
      const oReduced = {}
      aColumns.forEach(function (oColumn) {
        const sKey = oColumn.key
        switch (sKey) {
          case 'link':
            oReduced[sKey] = <Button data-report-tracker-id={oRecord.report_tracker_id} onClick={showProfile} size={'sm'}>View Report</Button>
            break

          case 'date':
            oReduced[sKey] = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(Date.parse(oRecord[sKey]))
            break

          default:
            oReduced[sKey] = oRecord[sKey]
            break
        }
      })
      aReturn.push(oReduced)
    })
    return aReturn
  }

  let oDataTable = null
  if (bShowReport) {
    oDataTable = (
      <>
        <ReportTrackerProfile reportId={reportId} closeHandler={closeProfile} />
      </>
    )
  } else {
    /*
     * Now we create the datatable, and display different things based on what we have.
     */
    if (bLoading) {
      oDataTable = (
        <Skeleton count={10} />
      )
    } else {
      if (aReportData.length > 0) {
        oDataTable = (
          <Row>
            <Col>
              <DataTable
                title={sTitle}
                columns={aColumns}
                data={aReducedReportData}
                pagination={true}
                striped={true}
                responsive={true}
                fixedHeader={true}
                theme={isDark ? 'custom-dark' : 'light'}
              />
            </Col>
          </Row>
        )
      } else {
        oDataTable = (
          oDataTable = (
            <p>No data to display</p>
          )
        )
      }
    }
  }

  return (
    <>
      {oDataTable}
    </>
  )
}

/**
 * Set up the prop stuff.
 * @type {{report: Validator<NonNullable<string>>, locations: Validator<NonNullable<any[]>>}}
 */
ReportTracker.propTypes = {
  report: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
}

export default ReportTracker
