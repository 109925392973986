import { useLocationContext } from 'context/Location'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Flex from 'components/common/Flex'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart } from 'echarts/charts'
import * as echarts from 'echarts/core'
import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { months, years } from 'data/common'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import Skeleton from 'react-loading-skeleton'
import { isIterableArray } from 'helpers/utils'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
])

const DfsGallonsPerDay = () => {
  // Bring the location context in.
  const { locationState, getDFSGallonsPerDayData } = useLocationContext()

  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up some default date things.
  const d = new Date()
  const sDefaultMonth = d.getMonth()
  const sDefaultYear = d.getFullYear()

  // Set up our local state and handlers.
  const [nLocation, setLocation] = useState(null)
  const [aLocations, setLocations] = useState([])
  const [month, setMonth] = useState(sDefaultMonth)
  const [year, setYear] = useState(sDefaultYear)
  const [average, setAverage] = useState(0)
  const [runningTotal, setRunningTotal] = useState(0);
  const [aReportData, setReportData] = useState([])
  const [bLoading, setLoading] = useState(false)
  const [aLabels, setLabels] = useState(null)
  const [aData, setData] = useState(null)
  const [sTitle, setTitle] = useState('DFS Gallons Per Day')

  /**
   * When the active location changes, or the locations, or the locationState
   * changes, we want to update our local states, so we have things to work with.
   */
  useEffect(() => {
    if (locationState.company != null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.dfs?.enabled) {
                return l
              }
              return null
            })
          }).flat().map(l => {
            return l.location_id
          }))
          setTitle('DFS Gallons Per Day - All Locations')
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.dfs?.enabled) {
                return l
              }
              return null
            }).map(l => {
              return l.location_id
            }))

          setTitle('DFS Gallons Per Day - Company Locations')
          break
        case 'l':
          setLocations([activeData.id])
          setTitle('DFS Gallons Per Day')
          break
      }
    }
  }, [locationState.company, activeData])

  /**
   * When the aLocations, month, or year are updated, do these things!
   */
  useEffect(() => {
    if (isIterableArray(aLocations)) {
      // Get the data and do something with it.
      setLoading(true)
      getDFSGallonsPerDayData(aLocations, month, year).then(function (data) {
        const aReorganizedData = []
        let dayofmonth
        if (data) {
          data.forEach(function (oRecord) {
            if (oRecord.completed_date) {
              dayofmonth = new Date(oRecord.completed_date + 'T08:00:00.000+00:00').getDate();
              aReorganizedData[dayofmonth] = oRecord.gallons_count ?? 0
            }
          })
          setReportData(aReorganizedData)
        } else {
          setReportData([])
        }
      })
    }
  }, [aLocations, month, year])

  /**
   * When the report data gets updated, we want to update the
   * labels and data things that get used to render the bar graph.
   */
  useEffect(() => {
    const aDates = getAllDaysInMonth(month, year)
    const aFilledData = [];
    let fAverage = 0;
    let fRunningTotal = 0;
    let nDays = 0;

    aDates.forEach(function (sDate) {
      // We only want to average out the days that come before TODAY, so if we're mid-month, we'll only include the days
      // that have passed, not the 0 days that are yet to come.
      if (new Date(sDate) <= new Date()) {
        fAverage = fAverage + parseFloat(aReportData[sDate] ?? 0)
        nDays++
      }
      aFilledData[sDate] = aReportData[sDate] ?? ''
    })

    fRunningTotal = fAverage;
    // Get the actual average and set it.
    fAverage = parseFloat(fAverage / nDays).toFixed(2);
    setAverage(new Intl.NumberFormat().format(fAverage));
    setRunningTotal(new Intl.NumberFormat().format(fRunningTotal));

    // Set the rest of the report data
    const aLabels = Object.keys(aFilledData)
    const aData = Object.values(aFilledData)

    setLabels(aLabels)
    setData(aData)
    setLoading(false)
  }, [aReportData])

  /**
   * Returns an array with all the dates in a month, in YYYY-MM-DD format.
   * @param month
   * @param year
   * @returns {*[]}
   */
  function getAllDaysInMonth (month = 0, year = 0) {
    const date = new Date(year, month, 1)
    const dates = []

    while (date.getMonth() === month) {
      dates.push(date.getDate());
      date.setDate(date.getDate() + 1)
    }

    return dates
  }

  // The month selector component.
  const oMonthSelector = (
    <Form.Select
      size='sm'
      value={month}
      onChange={e => setMonth(parseInt(e.target.value))}
      className='me-2'
      style={{ width: '200px' }}
    >
      {months.map((month, index) => (
        <option value={index} key={month}>
          {month}
        </option>
      ))}
    </Form.Select>
  )

  // The year selector component
  const oYearSelector = (
    <Form.Select
      size='sm'
      value={year}
      onChange={e => setYear(e.target.value)}
      className='me-2'
    >
      {years.map((year, index) => (
        <option value={year} key={year}>
          {year}
        </option>
      ))}
    </Form.Select>
  )

  const oAverageDisplay = (
    <div>
      <small>
      Total: {runningTotal}
      </small>
      &nbsp;|&nbsp;
      <small>
      Average: {average}
      </small>
    </div>
  )

  const labelOption = {
    show: true,
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15,
    formatter: '{c}', //  {name|{a}}',
    fontSize: 16,
    color: '#ffffff',
    rich: {
      name: {}
    }
  }

  // The config for our bar chart.
  const oConfig = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: aLabels,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
      {
        name: 'Gallons Per Day',
        type: 'bar',
        barWidth: '60%',
        data: aData,
        label: labelOption,
        color: 'rgb(6,165,96)'
      }
    ]
  }

  // Return things!
  return (
    <Card className='h-100'>
      <FalconCardHeader
        title={sTitle}
        titleTag='h4'
        className='pb-0'
        endEl={
          <Flex>
            {oMonthSelector}
            {oYearSelector}
          </Flex>
        }>
        {oAverageDisplay}
      </FalconCardHeader>
      <Card.Body>
        {
          bLoading
            ? <Skeleton count={8} />
            : <ReactEChartsCore
          echarts={echarts}
          option={oConfig}
          style={{ height: '18.4375rem' }}
        />
        }
      </Card.Body>
    </Card>
  )
}

export default DfsGallonsPerDay
