import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { formatDateTime, formatDollars } from 'helpers/utils'

const ViewRedemption = ({ redemption, show, hide, setShowNotes, onHide }) => {
  const [hasNotes, setHasNotes] = useState(false)

  const getSystemNoteCount = e => {
    if (redemption.system_notes === null || typeof redemption.system_notes === 'undefined') {
      return 0;
    } else {
      return Object.keys(redemption.system_notes).length;
    }
  }

  useEffect(() => {
    if (redemption.system_notes === null || typeof redemption.system_notes === 'undefined') {
      setHasNotes(false);
    } else {
      setHasNotes(true);
    }
  }, [redemption])

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={hide}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>View Redemption</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className='redeem-group'>
              <Form.Group as={Col} lg={12} controlId='viewRedeem'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reservation ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.rid}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Order ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.order_id}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Fleet</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.fleet_co}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.location_name}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Pump</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.pump}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Gallons</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.gallons}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.trx_status}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Retail Limit</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDollars(redemption.retail_limit)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Gallon Limit</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.gallon_limit}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Exceeded Gallons</Form.Label>
                    <Form.Control
                      type='text'
                      value={redemption.gallon_exceeded}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Retail</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDollars(redemption.retail)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Retail Date</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.retail_date)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Reserved On</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.reserved_on)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Redemption Started</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.redeem_started)}
                      disabled
                      readOnly
                    />
                  </Col>
                  <Col>
                    <Form.Label>Completed On</Form.Label>
                    <Form.Control
                      type='text'
                      value={formatDateTime(redemption.completed_on)}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row xs='auto'>
                <Col xxl>
                </Col>
                <Col>
                  <Button
                    type='button'
                    variant='secondary'
                    onClick={hide}
                  >
                    Close
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='button'
                    variant='primary'
                    disabled={!hasNotes}
                    onClick={setShowNotes}
                  >
                    Notes <Badge pill bg='secondary'>{getSystemNoteCount()}</Badge>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

ViewRedemption.propTypes = {
  redemption: PropTypes.object,
  show: PropTypes.bool,
  hide: PropTypes.func,
  setShowNotes: PropTypes.func,
  onHide: PropTypes.func,
}

ViewRedemption.defaultProps = {
  redemption: {},
}

export default ViewRedemption
