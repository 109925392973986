import React, { useEffect, useState, useContext } from 'react'
import PageHeader from 'components/common/PageHeader'
import DataTable from 'react-data-table-component'
import { useNotificationContext } from 'context/Notification'
import AppContext from 'context/Context'
import { dateTimeOptions } from 'helpers/formater'
import { Form, Button, Nav, Container, Row, Col } from 'react-bootstrap'
import createMarkup from 'helpers/createMarkup'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const Notifications = () => {
  const {
    config: { isDark }
  } = useContext(AppContext)

  const { notificationState, updateNotificationToRead } = useNotificationContext()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedUnreadRows, setSelectedUnreadRows] = useState([])
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    if (notificationState.notifications !== null) {
      setNotifications(notificationState.notifications)
    }
  }, [notificationState.notifications])

  const getNotePath = (n) => {
    switch (n.type) {
      case 'retail':
        return `/pricing?nid=${n.id}`

      default:
        return `/notifications/${n.id}`
    }
  }

  const columns = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      cell: (row) => {
        return <Link
          className={classNames(
            { 'fw-bold': !row.read },
          )}
          to={getNotePath(row)}
        >
          {row.title}
        </Link>
      }
    },
    {
      name: 'Date/Time',
      selector: row => row.createdAt,
      cell: (row) => {
        if (row.createdAt === null || row.createdAt === '') {
          return '';
        }
        return new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(row.createdAt))
      },
      sortable: true,
      width: '13rem',
    }
  ]

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRows(selectedRows)
    setSelectedUnreadRows(selectedRows.filter(r => {
      if (!r.read) {
        return true
      }
      return false
    }))
  }

  const handleMarkAsRead = e => {
    if (selectedRows !== null) {
      selectedUnreadRows.forEach(notification => {
        updateNotificationToRead(notification.id)
      })
    }
  }
  const [retailPriceRaw, setRetailPriceRaw] = useState(0)
  const [showRetailPriceModal, setShowRetailPriceModal] = useState(false)

  return (
    <>
      <PageHeader
        title='Notifications'
        description=""
        className='mb-3'
      />
      <>
        <Nav className='mb-2'>

          <Nav.Item className="ms-auto">
            <Button
              disabled={selectedUnreadRows < 1}
              onClick={handleMarkAsRead}
              className={'btn btn-sm'}>Mark Read</Button>
          </Nav.Item>

        </Nav>
        <DataTable
          columns={columns}
          data={notifications}
          theme={isDark ? 'custom-dark' : 'light'}
          selectableRows
          onSelectedRowsChange={handleSelectedRows}
          striped
          pagination
          fixedHeader
        />
      </>
    </>
  )
}

export default Notifications
