import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Main from './Main'
import 'helpers/initFA'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'G-TN711NPXMJ'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
)
