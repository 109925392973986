export const version = process.env.REACT_APP_VERSION
export const navbarBreakPoint = 'xl' // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg'
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: true,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'card'
}

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings }
