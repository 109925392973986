import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import LocationsOverview from './LocationsOverview'

import RewardsPerDay from './RewardsPerDay'
import DfsGallonsPerDay from './DfsGallonsPerDay'
import DfsGallonsPerMonth from './DfsGallonsPerMonth'
import { useFeatureFlagsContext } from 'context/FeatureFlags'
import { useLocationContext } from 'context/Location'
import { isIterableArray } from 'helpers/utils'

const CompanyBoard = () => {
  const { locationState } = useLocationContext()
  const [hasDFS, setHasDFS] = useState(false)
  const [hasRewards, setHasRewards] = useState(false) // defaulting to true for the moment, reset to start as false later

  useEffect(() => {
    if (locationState.company !== null && isIterableArray(locationState.company)) {
      locationState.company.forEach(comp => {
        if (comp.options.dfs?.enabled) {
          setHasDFS(true);
        }
        if (comp.options.rewards?.enabled) {
          setHasRewards(true);
        }
      })
    }
  }, [locationState.company])

  return (
    <>
      <Row className={'g3 mb-3'}>
        <Col lg={12} >
          <LocationsOverview />
        </Col>
      </Row>

      {
        // DFS gallons per day widget.
        hasDFS
          ? <Row className={'g3 mb-3'}>
            <Col lg={12}>
              <DfsGallonsPerDay />
            </Col>
          </Row>
          : ''
      }

      {
        // DFS gallons per day widget.
        hasDFS
          ? <Row className={'g3 mb-3'}>
            <Col lg={12}>
              <DfsGallonsPerMonth />
            </Col>
          </Row>
          : ''
      }

      {
        // Rewards points per day widget.
        hasRewards
          ? <Row className={'g3 mb-3'}>
            <Col lg={12}>
              <RewardsPerDay />
            </Col>
          </Row>
          : ''
      }
    </>
  )
}

export default CompanyBoard
