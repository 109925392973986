import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCookies } from 'react-cookie'

import { useAccountContext } from 'context/Account'

const LoginForm = ({ hasLabel, success, chgPass }) => {
  const [cookies, setCookie] = useCookies(['username'])
  // State
  const [isLoggingIn, setLoggingIn] = useState(false)
  // eslint-disable-next-line
  const [hasAuthed, setHasAuthed] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  // const [passwordRequired, setPasswordRequired] = useState(true)
  const [formData, setFormData] = useState({
    username: cookies.username ?? '',
    password: '',
    remember:
      !!(Object.getOwnPropertyDescriptor(cookies, 'username') &&
      cookies.username !== '')
  })

  useEffect(() => {
    if (formData.username && formData.password) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  const { authenticate } = useAccountContext()

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoggingIn(true)

    if (formData.remember) {
      setCookie('username', formData.username, { path: '/' })
    } else {
      setCookie('username', '')
    }

    try {
      const user = await authenticate(formData.username, formData.password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        chgPass(user)
      }
    } catch (error) {
      toast.error(
        error.message,
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )

      setLoggingIn(false)
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Username</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Username' : ''}
          value={formData.username}
          name='username'
          onChange={handleFieldChange}
          type='phone'
        />
      </Form.Group>

      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          name='password'
          onChange={handleFieldChange}
          type='password'
        />
      </Form.Group>

      <Row className='justify-content-between align-items-center'>
        <Col xs='auto'>
          <Form.Check type='checkbox' id='rememberMe'>
            <Form.Check
              type='switch'
              label='Remember Me'
              name='remember'
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })}
            />
          </Form.Check>
        </Col>
        <Col xs='auto'>
          <Button
            variant='link'
            style={{ fontSize: '.8333333333rem', fontWeight: '500!important' }}
            href='/forgot'
          >Forgot Password
          </Button>
        </Col>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <Button
            type='submit'
            variant='primary'
            className='mt-3 w-100'
            disabled={!isSubmitable}
          >
            {isLoggingIn
              ? (
                <FontAwesomeIcon
                  icon='circle-notch'
                  className='me-1 fa-spinner fa-spin'
                />
                )
              : (
                  ''
                )}
            Log in
          </Button>
        </Form.Group>

        <Form.Group as={Col}>
          <Button
            type='null'
            variant='info'
            href='/register'
            className='mt-3 w-100'
          >
            Register
          </Button>
        </Form.Group>
      </Row>
    </Form>
  )
}

LoginForm.propTypes = {
  success: PropTypes.func,
  chgPass: PropTypes.func,
  hasLabel: PropTypes.bool
}

LoginForm.defaultProps = {
  hasLabel: false
}

export default LoginForm
