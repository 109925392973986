const awsconfig = {
  Auth: {
    region: 'us-west-2',
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_USERPOOL_DOMAIN,
      redirectSignIn: process.env.REACT_APP_APP_SIGNIN_REDIRECT,
      redirectSignOut: process.env.REACT_APP_APP_SIGNOUT_REDIRECT,
      responseType: 'code',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    }
  },
  API: {
    aws_appsync_region: 'us-west-2',
    aws_appsync_graphqlEndpoint: `https://${process.env.REACT_APP_GQL_DOMAIN}/graphql`,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  }
}

export default awsconfig
