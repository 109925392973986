import React from 'react'
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import LoginPage from 'pages/authentication'
import RegisterPage from 'pages/registration'
import ForgotPage from 'pages/forgot'
import ErrorLayout from './ErrorLayout'
import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'
import { toast, ToastContainer } from 'react-toastify'
import { CloseButton } from 'components/common/Toast'

const AuthSimpleLayout = () => (
  <>
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>

      <Route path="/register" element={<RegisterPage />} />
      <Route path="/forgot" element={<ForgotPage />} />
      <Route path="/" element={<LoginPage />} />
        {/* <Redirect to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={true}
        position={toast.POSITION.TOP_LEFT}
        // autoClose=false
        theme='colored'
      />
  </>
)

export default AuthSimpleLayout
