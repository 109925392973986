/**
 * devices/index.jsx
 * Page component used to display the customer's devices.
 * @author John Nebel <john@roadyscorp.com>
 */
import React, { useEffect, useState } from 'react'
import { useLocationContext } from 'context/Location'
import PageHeader from 'components/common/PageHeader'
import { isIterableArray } from '../../helpers/utils';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DataTable from 'react-data-table-component';
import EditDevice from './EditDevice';

/**
 * Creates the display for the DevicesPage, the component that encompasses the page as a whole
 * @returns {JSX.Element}
 * @constructor
 */
const DevicesPage = () => {
  // Let's get the location context up in here.
  const { locationState, getTerminalDevices } = useLocationContext()
  const [activeData, setActiveData] = useState(null)
  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up our local state and handlers.
  const [aLocations, setLocations] = useState([])
  const [oDisplayData, setDisplayData] = useState([]);
  const [nEditing, setEditing] = useState(null);
  const [bShowInactive, setShowInactive] = useState(false);
  const [aStoredData, setStoredData] = useState([]);

  /**
   * When the active view changes, or the company/location data
   * changes, we want to update our local states, so we have things to work
   * with.
   */
  useEffect(() => {
    if (locationState.company !== null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.rewards?.enabled) {
                return l
              }
              return null
            })
          }).flat().map(l => {
            return l.location_id
          }))
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.rewards?.enabled) {
                return l
              }
              return null
            }).map(l => {
              return l.location_id
            }))
          break
        case 'l':
          setLocations([activeData.id])
          break
      }
    }
  }, [activeData, locationState.company]);

  /**
   * When the activeData, sStartDate, or sEndDate are updated, do these things!
   */
  useEffect(() => {
    // If we have locations, lets do things.
    if (activeData !== null && isIterableArray(aLocations)) {
      // Set up a default set of filters
      const oFilters = {
        locations: aLocations,
      }

      // Get the data and do something with it.
      getTerminalDevices(aLocations, oFilters).then(function (data) {
        if (data) {
          console.log('got data: ', data, ' .. updating stored cache.');
          data.forEach((item) => {
            const nDeviceId = item.device_id;
            aStoredData[nDeviceId] = item;
          })
          setStoredData(aStoredData);
          const oTableData = createTableData(data);
          setDisplayData(oTableData);
          // Hide all rows there inactive_date is not null.
        } else {
          const oTableData = createDisplayStatus('Notice', 'No device data found.');
          setDisplayData(oTableData);
        }
      });
    }
  }, [activeData, aLocations, nEditing, bShowInactive]);

  /**
   * Display the status of the data.
   * @param sTitle
   * @param sStatus
   * @returns {JSX.Element}
   */
  const createDisplayStatus = (sTitle, sStatus) => {
    console.log('Creating display status: ', sTitle, sStatus);

    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="text-center">
          <h2 className="mb-3">{sTitle}</h2>
          <p>{sStatus}</p>
        </div>
      </div>
    );
  }

  /**
   * Build the active widget.
   * @param item
   * @returns {JSX.Element}
   */
  const buildActiveWidget = (item) => {
    let sActive = 'No';
    if (item.active) {
      sActive = 'Yes';
    }

    return (
      <div className='activeSwitch'>
        {sActive}
      </div>
    );
  };

  /**
   * Edit the data.
   * @param e
   */
  const editClickHandler = (e) => {
    // This button is in a table row -- when clicked, we want to turn the row's background color to yellow.
    const oRow = e.target.closest('.rdt_TableRow');
    oRow.classList.add('bg-primary');
    console.log('We are editing: ', e.target.dataset.attrDeviceId);
    setEditing(e.target.dataset.attrDeviceId);
  };

  /**
   * Remove row highlights.
   */
  const removeRowHighlights = () => {
    const aRows = document.querySelectorAll('.rdt_TableRow');
    aRows.forEach((oRow) => {
      oRow.classList.remove('bg-primary');
    });
  }

  /**
   * Save the edit.
   * @param e
   */
  const saveClickHandler = (e) => {
    console.log('Received save request; ', e);
    removeRowHighlights();
    // TODO: RELOAD DATA HERE.
    setEditing(null);
  }

  /**
   * Cancel the edit.
   * @param e
   */
  const cancelClickHandler = (e) => {
    console.log('Received cancel request: ', e);
    removeRowHighlights();
    setEditing(null);
  }

  /**
   * Build the action widget.
   * @returns {JSX.Element}
   */
  const buildActionWidget = (item) => {
    return (
      <div className="d-flex">
        <Button data-attr-device-id={item.device_id} onClick={editClickHandler}
                className="btn btn-sm btn-primary">Edit</Button>
      </div>
    );
  }

  /**
   * Build the placement widget.
   * @param item
   * @returns {JSX.Element}
   */
  const buildPlacementWidget = (item) => {
    const sCurrentPlacement = item.meta.placement;
    return (
      <div className="d-flex placementSelector">
        {sCurrentPlacement}
      </div>
    );
  }

  /**
   * Toggle the show inactive flag.
   */
  const toggleShowInactive = () => {
    setShowInactive(!bShowInactive);
  }

  /**
   * Create the table data for the devices.
   * @param data
   * @returns {JSX.Element}
   */
  const createTableData = (data) => {
    const aReportData = [];

    const typeMapping = {
      10: 'Kiosk',
      9: 'Hypercom',
      8: 'E-Commerce',
      7: 'Other',
      6: 'Exadigm',
      5: 'Ingenico',
      4: 'Trendar',
      3: 'Lipman',
      2: 'Virtual',
      1: 'Unknown'
    };

    data.forEach((item, index) => {
      const aMeta = item.meta ?? [];
      const sType = typeMapping[item.type_id] || 'Other';
      const sPrograms = aMeta.programs.join(', ') || 'None';
      const nLocationId = item.location_id;

      const sPlacement = buildPlacementWidget(item);
      const sActions = buildActionWidget(item);
      const sActive = buildActiveWidget(item);
      const sDate = new Date(item.created_dtz).toLocaleString();

      // We're skipping inactive devices, unless bShowInactive is toggled.
      if (item.inactive_date === null || bShowInactive) {
        aReportData.push({
          device_id: item.device_id,
          location_id: nLocationId,
          active: sActive,
          created_dtz: sDate,
          type_id: sType,
          programs: sPrograms,
          placement: sPlacement,
          actions: sActions
        });
      }
    });

    // Sort the report data by device_id ascending.
    aReportData.sort((a, b) => a.device_id - b.device_id);

    const aColumns = [
      {
        name: 'Device ID',
        selector: row => row.device_id,
        key: 'device_id',
        width: '9em'
      },
      {
        name: 'LID',
        selector: row => row.location_id,
        key: 'location_id',
        width: '9em'
      },
      {
        name: 'Active',
        selector: row => row.active,
        key: 'active',
        width: '7em'
      },
      {
        name: 'Created Date',
        selector: row => row.created_dtz,
        key: 'created_dtz',
      },
      {
        name: 'Type',
        selector: row => row.type_id,
        key: 'type_id',
        width: '9em'
      },
      {
        name: 'Programs',
        selector: row => row.programs,
        key: 'programs',
      },
      {
        name: 'Placement',
        selector: row => row.placement,
        key: 'placement',
      },
      {
        name: 'Actions',
        selector: row => row.actions,
        key: 'actions',
      }
    ];

    console.log('Creating table with data: ', data);

    const sTitle = 'Terminal Devices';
    const isDark = true;

    return (
      <Row>
        <Col>
          <DataTable
            title={sTitle}
            columns={aColumns}
            data={aReportData}
            pagination={true}
            striped={true}
            responsive={true}
            fixedHeader={true}
            theme={isDark ? 'custom-dark' : 'light'}
          />
        </Col>
      </Row>
    );
  }

  // Set up the page header.
  const oPageHeader = (
    <PageHeader
      title='Devices'
      description=""
      className='mb-3'
    />
  );

  let oEditModal = null;
  if (nEditing !== null) {
    oEditModal = (
      <EditDevice
        save={saveClickHandler}
        cancel={cancelClickHandler}
        device={aStoredData[nEditing]}
      />
    );
  }

  /**
   * The active toggle.
   * Shows/hides inactive kiosks.
   * Removed from the page for now, but leaving it here in case we
   * want to reintroduce it at some point.
   * @type {JSX.Element}
   */
  const oActiveToggle = (
    <Row>
      <Col>
        <Button
          variant='primary'
          onClick={toggleShowInactive}
        >
          {bShowInactive ? 'Hide Inactive' : 'Show Inactive'}
        </Button>
      </Col>
    </Row>
  );
  // Send it all back!
  return (
    <>
      {oEditModal}
      {oPageHeader}
      {oDisplayData}
    </>
  )
}

export default DevicesPage
