import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card, Col, Row } from 'react-bootstrap'
import Flex from 'components/common/Flex'
import Background from './Background'
import corner4 from 'assets/img/illustrations/corner-4.png'
import createMarkup from 'helpers/createMarkup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dateTimeOptions } from 'helpers/formater'

const PageHeader = ({
  title,
  preTitle,
  titleTag: TitleTag,
  description,
  image,
  col,
  children,
  date,
  type,
  ...rest
}) => {
  const iconFA = (type) => {
    switch (type) {
      case 'retail':
        return {
          icon: 'dollar-sign',
          color: 'green'
        }

      default:
        return {
          icon: 'message',
          color: 'grey'
        }
    }
  }

  return (
    <Card {...rest}>
      <Background
        image={image}
        className='bg-card'
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className='position-relative'>
        <Row>
          <Col {...col}>
            {preTitle && <h6 className='text-600'>{preTitle}</h6>}
            <Flex justifyContent='between' alignItems='center'>

              <TitleTag className='mb-0'>{type && (
                <span className='fa-layers fa-fw fs-3'>
                  <FontAwesomeIcon icon="circle" color={iconFA(type).color} />
                  <FontAwesomeIcon icon={iconFA(type).icon} inverse transform="shrink-8" />
                </span>
              )} {title}</TitleTag>
              {date && (<div className='text-end'>{new Intl.DateTimeFormat('en-US', dateTimeOptions).format(new Date(date))}</div>)}
            </Flex>
            {description && (
              <p
                className={classNames('mt-2', { 'mb-0': !children })}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            )}
            {children}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  image: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node
}

PageHeader.defaultProps = { col: { lg: 12 }, image: corner4, titleTag: 'h3' }

export default PageHeader
