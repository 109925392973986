import { getItemFromStore, setItemToStore } from 'helpers/utils'

const storedFlags = getItemFromStore('flags', {})

export const initialState = Object.fromEntries(
  Object.entries(storedFlags).map((k, v) => {
    return k
  })
)

export const FeatureFlagsReducer = (initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'SET_CONFIG':
      setItemToStore('flags', JSON.stringify(payload))

      // eslint-disable-next-line
      const configs = Object.fromEntries(
        Object.entries(payload).map((k, v) => {
          return k
        })
      )

      return {
        ...initialState,
        ...configs
      }

    case 'UPDATE_CONFIG':
      return {
        ...initialState,
        config: payload.config,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
