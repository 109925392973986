import React, { useState, useEffect } from 'react'
import Flex from 'components/common/Flex'
import Section from 'components/common/Section'
import { Card, Col, Row } from 'react-bootstrap'
import Logo from 'components/common/Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForgotForm from './ForgotForm'
import ForgotConfirmationForm from './ForgotConfirmationForm'

const ForgotSuccess = () => {
  useEffect(() => {
    window.setTimeout(function () {
      window.location.href = '/'
    }, 5000)
  })

  return (
    <>
      <Flex justifyContent='center' alignItems='center' className='mb-2'>
        <FontAwesomeIcon
          icon='check-circle'
          className='me-1'
          style={{ color: 'green', fontSize: '8em' }}
        />
      </Flex>
      <h5>Your password has now been updated.  Please proceed to login to access your account.</h5>
    </>
  )
}

const ForgotPage = () => {
  const [regStatus, setRegStatus] = useState(false)
  const [username, setUserName] = useState('')
  const [confStatus, setConfStatus] = useState(false)

  return (
    <Section className='py-0'>
      <Row className='flex-center min-vh-100 py-6'>
        <Col sm={10} md={8} lg={6} xl={5} className='col-xxl-4'>
          <Logo width={200} />
          <Card>
            <Card.Body className='p-4 p-sm-5'>
              {!regStatus
                ? (
                  <>
                    <Flex justifyContent='between' alignItems='center' className='mb-2'>
                      <h5>Please enter your email address to begin</h5>
                    </Flex>
                    <ForgotForm
                      success={username => {
                        setRegStatus(true)
                        setUserName(username)
                      }}
                    />
                  </>
                  )
                : !confStatus
                    ? (
                      <>
                        <Flex justifyContent='between' alignItems='center' className='mb-2'>
                          <h5>A confirmation code has been sent to your email address.</h5>
                        </Flex>
                        <Flex justifyContent='between' alignItems='center' className='mb-2'>
                          <h5>Please enter the code below along with a new password to access your account.</h5>
                        </Flex>
                        <ForgotConfirmationForm
                          hasLabel={false}
                          userName={username}
                          success={() => setConfStatus(true)}
                        />
                      </>
                      )
                    : (
                      <ForgotSuccess />
                      )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}

export default ForgotPage
