import React from 'react'
import { Col, Row } from 'react-bootstrap'
import GitInfo from 'react-git-info/macro'

const Footer = () => {
  const gitInfo = GitInfo()

  let version = ''

  if (process.env.REACT_APP_VERSION) {
    version += '| ' + process.env.REACT_APP_VERSION + ' '
  }

  version += '| ' + gitInfo.commit.shortHash

  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' Sky Capital Group LLC. All rights reserved.'}
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">
            {process.env.REACT_APP_ENV.toUpperCase() + ' Environment '}
            {version}
          </p>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
