import React, { useState, useEffect } from 'react'
import Flex from 'components/common/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RegisterForm from './RegisterForm'
import RegistrationConfirmationForm from './RegistrationConfirmationForm'
import { Card, Col, Row } from 'react-bootstrap'
import Section from 'components/common/Section'

const RegistrationSuccess = () => {
  useEffect(() => {
    window.setTimeout(function () {
      window.location.href = '/'
    }, 10000)
  })

  return (
    <>
      <Flex justifyContent='center' alignItems='center' className='mb-2'>
        <FontAwesomeIcon
          icon='check-circle'
          className='me-1'
          style={{ color: 'green', fontSize: '8em' }}
        />
      </Flex>
      <Flex justifyContent='between' alignItems='center' className='mb-2'>
        <h3>Thank you for registering and confirming your account!</h3>
      </Flex>
    </>
  )
}

const Register = () => {
  const [regStatus, setRegStatus] = useState(false)
  // eslint-disable-next-line
  const [userName, setUserName] = useState('')
  const [confStatus, setConfStatus] = useState(false)

  return (
    <>
      {!regStatus
        ? (
          <>
            <Flex justifyContent='between' alignItems='center' className='mb-2'>
              <h5>Complete the form to register your {process.env.REACT_APP_PRODUCT_NAME} account</h5>
            </Flex>
            <RegisterForm
              success={userName => {
                setRegStatus(true)
                setUserName(userName)
              }}
            />
          </>
          )
        : !confStatus
            ? (
              <>
                <Flex justifyContent='between' alignItems='center' className='mb-2'>
                  <h4>Thank you for registering.</h4>
                </Flex>
                <Flex justifyContent='between' alignItems='center' className='mb-2'>
                  <h5>
                    A confirmation code has been sent to your email address.
                    Please enter the confirmation code below to confirm your account.
                  </h5>
                </Flex>
                <RegistrationConfirmationForm
                  hasLabel={false}
                  userName={userName}
                  success={() => setConfStatus(true)}
                />
              </>
              )
            : (
              <RegistrationSuccess />
              )}
    </>
  )
}

const RegisterPage = () => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
        {/* <Logo width={200} /> */}
        <Card>
          <Card.Body className="p-4 p-sm-5">
            <Register />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Section>
)

export default RegisterPage
