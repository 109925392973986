import React, { useState, useContext, useEffect } from 'react'
import {
  // Navigate,
  Route,
  Routes
} from 'react-router-dom'
import is from 'is_js'
import MainLayout from './MainLayout'

import Profile from 'pages/profile'
import FaqPage from 'pages/faq'
import PrivacyPage from 'pages/privacy'
import TermsPage from 'pages/terms'
import TutorialPage from 'pages/tutorial'
import DFSSettlementsPage from 'pages/dfs_settlements'
import DFSDailySummariesPage from 'pages/dfs_dailysummaries'
import DFSMerchantWeeklySummariesPage from 'pages/dfs_merchant_summary'
import DFSRedemptionsPage from 'pages/dfs_redemptions'
import DFSReservationsPage from 'pages/dfs_reservations'
import RetailPrices from 'pages/pricing'
import CompetitorPrices from 'pages/pricing/competitor'
import RewardsBillingPage from 'pages/rewards_billing'
import RewardsLocationSummaryPage from 'pages/rewards_location_summary'
import DevicesPage from 'pages/devices'

import ErrorLayout from './ErrorLayout'
import { toast, ToastContainer } from 'react-toastify'
import { CloseButton } from 'components/common/Toast'

import Error404 from 'components/errors/Error404'
import Error500 from 'components/errors/Error500'

import Dashboard from 'pages/dashboard'
import Notifications from 'pages/notifications'
import AppContext from 'context/Context'
import { useAccountContext } from 'context/Account'
import UnconfiguredAppLayout from './UnconfiguredAppLayout'
import UnconfiguredPage from 'pages/unconfigured'
import Notification from 'pages/notifications/notification'

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList
  useContext(AppContext)
  const { appState } = useAccountContext()

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows')
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome')
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox')
    }
  }, [HTMLClassList])

  const [hasCompanyAttrib, setHasCompanyAttrib] = useState(false)

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated
      && appState.attributes !== null
      && Object.prototype.hasOwnProperty.call(appState.attributes, 'custom:companyid')) {
      setHasCompanyAttrib(true)
    }

    if (process.env.REACT_APP_ENV === 'demo') {
      setHasCompanyAttrib(true)
    }
  }, [appState])

  return (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

        {/* --- MainLayout Starts  */}
        {!hasCompanyAttrib
          ? <Route element={<UnconfiguredAppLayout />}>
              <Route path="/" element={<UnconfiguredPage />} />
              <Route path="user/profile" element={<Profile />} />
            </Route>
          : <Route element={<MainLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="user/profile" element={<Profile />} />
              <Route path="faq" element={<FaqPage />} />
              <Route path="privacy" element={<PrivacyPage />} />
              <Route path="terms" element={<TermsPage />} />
              <Route path="tutorial" element={<TutorialPage />} />
              <Route path="dfs/settlements" element={<DFSSettlementsPage />} />
              <Route path="dfs/dailysummaries" element={<DFSDailySummariesPage />} />
              <Route path="dfs/merchantsummary" element={<DFSMerchantWeeklySummariesPage />} />
              <Route path="dfs/reservations" element={<DFSReservationsPage />} />
              <Route path="dfs/redemptions" element={<DFSRedemptionsPage />} />
              <Route path="rewards/billing" element={<RewardsBillingPage />} />
              <Route path="rewards/locationsummary" element={<RewardsLocationSummaryPage />} />
              <Route path="pricing" element={<RetailPrices/>} />
              <Route path="pricing/competitor" element={<CompetitorPrices/>} />
              <Route path="devices" element={<DevicesPage />} />
              <Route path="notifications/:noteId" element={<Notification />} />
            </Route>
        }

        {/* --- MainLayout end  */}

        {/* <Route path="*" element={<Navigate to="/errors/404" replace />} /> */}
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={true}
        position={toast.POSITION.TOP_LEFT}
        // autoClose=false
        theme='colored'
      />
    </>
  )
}

export default Layout
