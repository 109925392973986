import gql from 'graphql-tag'

export const listUnreadUserNotifications = gql(`
  {
    listUserNotifications(filter: {read: {eq: false}}) {
      items {
        createdAt
        type
        id
        message
        read
        title
        updatedAt
      }
    }
  }`
)

export const listAllUserNotifications = gql(`
  {
    listUserNotifications {
      items {
        createdAt
        type
        id
        message
        read
        title
        updatedAt
      }
    }
  }`
)
