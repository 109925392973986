import React from 'react'
import Lottie from 'lottie-react'
import { Card, Col, Row } from 'react-bootstrap'
import Section from 'components/common/Section'
import Construction from 'assets/img/animated-icons/website-build.json'

const UnconfiguredPage = () => (
  <Section className='py-0'>
    <Row className='flex-center min-vh-100 py-6'>
      <Col sm={10} md={8} lg={6} xl={5} className='col-xxl-4'>
        <Card>
          <Card.Title className='px-4 pt-4 text-center'>
            {process.env.REACT_APP_PRODUCT_NAME}
          </Card.Title>
          <Card.Body className='px-4 pt-0'>
            <Lottie animationData={Construction} loop={true} />
            Thank you for registering for an account.
            Please be patient as your experience is being customized and configured.
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Section>
)

export default UnconfiguredPage
