import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useLocationContext } from '../../context/Location';

const EditDevice = (props) => {
  const oDevice = props.device;
  const cancelCallback = props.cancel;
  const saveCallback = props.save;

  const defaultDevice = {
    device_id: oDevice.device_id || '',
    placement: oDevice.meta.placement || ''
  }
  const [isProcessing, setIsProcessing] = useState(false)
  const [formData, setFormData] = useState(defaultDevice)
  const { editDeviceRequest } = useLocationContext();

  useEffect(() => {
    setFormData({
      ...formData,
      placement: oDevice.meta.placement || '',
    })
  }, [oDevice])

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleCancel = () => {
    console.log('CANCELLING');
    cancelCallback();
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    try {
      const oPayload = {
        device_id: parseInt(oDevice.device_id),
        ...formData
      };

      console.log('Submitting form data: ', oPayload, ' for device: ', oDevice.device_id);
      const oResponse = await editDeviceRequest(oPayload);
      setIsProcessing(false)

      if (oResponse.status !== 200) {
        toast.error('Error: ' + oResponse.status, {
          autoClose: false
        });
        return false;
      }

      if (!oResponse || !oResponse.data) {
        toast.error('Successful but unknown response received from server', {
          autoClose: false
        });
        return false;
      }

      if (oResponse.data.error) {
        toast.error(oResponse.error.message, {
          autoClose: false
        });
        return false;
      }

      toast.success('Edit device request successfully posted');
      saveCallback()
    } catch (error) {
      setIsProcessing(false)
      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  return (
    <>
      <Modal
        centered
        show={true}
        onHide={handleCancel}
        size={'xl'}
      >
        <>
          <Modal.Header className='modal-header-success'>
            <Modal.Title>Edit Device</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Col} lg={12} controlId='editDevice'>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Device ID</Form.Label>
                    <Form.Control
                      type='text'
                      value={oDevice.device_id}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Location</Form.Label>
                    <Form.Control
                      type='text'
                      value={oDevice.location_id}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>
                <Row className='mb-3 g-3'>
                  <Col>
                    <Form.Label>Placement</Form.Label>
                    <Form.Select
                      type='select'
                      value={formData.placement}
                      onChange={handleFieldChange}
                      defaultValue={oDevice.meta.placement}
                      name='placement'
                      placeholder='Select Placement'
                    >
                      <option value=''>Select Placement</option>
                      <option value='Fuel Desk'>Fuel Desk</option>
                      <option value='Restaurant'>Restaurant</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Row className='mb-3 g-3'>
                <Col className='text-end'>
                  <Button
                    variant='secondary'
                    type='button'
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col lg='2'>
                  <Button
                    variant='primary'
                    type='submit'
                  >
                    {isProcessing
                      ? (
                        <FontAwesomeIcon
                          icon='circle-notch'
                          className='me-1 fa-spinner fa-spin'
                        />
                        )
                      : ''
                    }
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </>
      </Modal>
    </>
  )
}

EditDevice.propTypes = {
  device: PropTypes.object,
  save: PropTypes.func,
  cancel: PropTypes.func,
}

EditDevice.defaultProps = {
  device: {},
  save: () => { return true; },
  cancel: () => { return true; }
}

export default EditDevice
