import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'context/Account'
import { toast } from 'react-toastify'

const ChangeUsername = ({ hasLabel, show, hide, email }) => {
  const { changeAttributes, verifyEmail } = useAccountContext()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [isConfirmSubmitable, setIsConfirmSubmitable] = useState(false)
  const [didUserChange, setDidUserChange] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    code: '',
  })

  useEffect(() => {
    if (email !== null) {
      setFormData({
        ...formData,
        email: email,
      })
    }
  }, [email])

  useEffect(() => {
    if (formData.email !== email) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  useEffect(() => {
    if (formData.code !== null) {
      setIsConfirmSubmitable(true)
    } else {
      setIsConfirmSubmitable(false)
    }
  }, [formData])

  const handleChange = e => {
    const fieldValue = e.target.value

    setFormData({
      ...formData,
      [e.target.name]: fieldValue,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    const data = {}

    if (formData.email !== email) {
      data.email = formData.email
    }

    try {
      await changeAttributes(data)
      setIsProcessing(false)

      toast.success('Email/Username successfully changed')
      setDidUserChange(true)
    } catch (error) {
      setIsProcessing(false)

      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  const handleConfirmation = async (e) => {
    e.preventDefault()
    setIsProcessing(true)

    try {
      await verifyEmail(formData.code)
      setIsProcessing(false)

      toast.success('Email address successfully confirmed')
      setDidUserChange(false)
      setDidUserChange(false)
      hide()
    } catch (error) {
      setIsProcessing(false)

      toast.error(
        error.message,
        {
          autoClose: false,
        }
      )
    }
  }

  const resetForm = () => {
    setFormData({
      email: email,
      code: '',
    })
  }

  return (
    <>
      <Modal centered show={show} onShow={resetForm} onHide={hide}>
        {!didUserChange
          ? <>
              <Modal.Header className='modal-header-success'>
                <Modal.Title>Change Email/Username</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleSubmit}>
                  <Row className='mb-3 g-3'>
                    <Form.Group as={Col} lg={12} controlId='email'>
                    {hasLabel && <Form.Label>Email/Username</Form.Label>}
                      <Form.Control
                        type='text'
                        placeholder={!hasLabel ? 'Email/Username' : ''}
                        value={formData.email}
                        name='email'
                        onChange={handleChange}
                      />
                      <Form.Text id="passwordHelpBlock" muted>
                        Your username and email are the same.  To ensure access please make sure to use a valid email address.
                      </Form.Text>
                    </Form.Group>
                  </Row>
                  <Row className='g-3'>
                    <Col>
                      <Button
                        variant='secondary'
                        type='button'
                        onClick={hide}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col className='text-end'>
                      <Button
                        variant='primary'
                        type='submit'
                        disabled={!isSubmitable}
                      >
                        {isProcessing
                          ? (
                            <FontAwesomeIcon
                              icon='circle-notch'
                              className='me-1 fa-spinner fa-spin'
                            />
                            )
                          : ''
                        }
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </>
          : <>
              <Modal.Header className='modal-header-success'>
                <Modal.Title>Confirm Email/Username</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleConfirmation}>
                  <Row className='mb-3 g-3'>
                    Your email address has been successfully updated.  An message containing a
                    confirmation code has been sent to your updated email address.  Please
                    enter the code below to confirm reception and ensure email notifications continue to
                    deliver.
                  </Row>
                  <Row className='mb-3 g-3'>
                    <Form.Group as={Col} lg={12} controlId='code'>
                      {hasLabel && <Form.Label>Confirmation Code</Form.Label>}
                      <Form.Control
                        type='text'
                        placeholder={!hasLabel ? 'Confirmation Code' : ''}
                        value={formData.code}
                        name='code'
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                  <Row className='g-3'>
                    <Col className='text-end'>
                      <Button
                        variant='primary'
                        type='submit'
                        disabled={!isConfirmSubmitable}
                      >
                        {isProcessing
                          ? (
                            <FontAwesomeIcon
                              icon='circle-notch'
                              className='me-1 fa-spinner fa-spin'
                            />
                            )
                          : ''
                        }
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </>
        }
      </Modal>
    </>
  )
}

ChangeUsername.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func,
  hasLabel: PropTypes.bool,
  email: PropTypes.string
}

ChangeUsername.defaultProps = {
  hasLabel: true
}

export default ChangeUsername
