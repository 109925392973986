import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Flex from 'components/common/Flex'
import LoginForm from './LoginForm'
import PasswordResetForm from './PasswordResetForm'
import SocialAuthButtons from './SocialAuthButtons'
import Divider from 'components/common/Divider'
import Section from 'components/common/Section'
import { Card, Col, Row } from 'react-bootstrap'

const Login = ({ success, chgPass }) => (
  <>
    <Flex justifyContent='between' alignItems='center' className='mt-2'>
      <h6>Welcome! Please sign in:</h6>
    </Flex>
    <LoginForm success={success} chgPass={chgPass}/>
    <Divider className='mt-4'>or log in with</Divider>
    <SocialAuthButtons />
  </>
)

Login.propTypes = {
  success: PropTypes.func,
  chgPass: PropTypes.func,
  hasLabel: PropTypes.bool
}

Login.defaultProps = {
  hasLabel: false
}

const PasswordReset = ({ hasLabel, user }) => (
  <>
    <Flex justifyContent='between' alignItems='center' className='mt-2'>
      <h6>Your account has been put into administrator reset.  Please update your password below:</h6>
    </Flex>
    <PasswordResetForm user={user} />
  </>
)

PasswordReset.propTypes = {
  user: PropTypes.object,
  hasLabel: PropTypes.bool
}

PasswordReset.defaultProps = {
  hasLabel: false
}

const LoginPage = () => {
  // eslint-disable-next-line
  const [loginStatus, setLoginStatus] = useState(false)
  const [passwordRequired, setPasswordRequired] = useState(false)
  const [user, setUser] = useState(null)

  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Card>
            <Card.Title className="px-4 pt-4 text-center">
              {process.env.REACT_APP_PRODUCT_NAME}
            </Card.Title>
            <Card.Body className="px-4 pt-0">
            {
              !passwordRequired
                ? <Login
                success={user => {
                  setLoginStatus(true)
                  setUser(user)
                }}
                chgPass={
                  user => {
                    setPasswordRequired(true)
                    setUser(user)
                  }
                }
                />
                : <PasswordReset user={user} />
            }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}

export default LoginPage
